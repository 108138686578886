import React, { useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import clsx from "clsx";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { setPasswordSchema } from "./validation";
import { setPassword } from "../../api";
import { setToken } from "../../helpers/util";
import ServerErrors from "../../components/server-error";
import Loader from "../../components/loader/Loader";
import FormButton from "../../components/agent-form-control/FormButton";
import FormLabel from "../../components/agent-form-control/FormLabel";
import FormInput from "../../components/agent-form-control/FormInput";
import LoginBannerComponent from "../login/LoginBannerComponent";
import logo from "../../../assets/images/logotxt.svg";

import TogglePasswordVisibilityButton from "../../components/password-visibility/TogglePasswordVisibilityButton";
import FormError from "../../components/form-control/FormError";

const SetPasswordForm = () => {
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const [params] = useSearchParams();
  const uniqueHash = params.get("unique_hash");

  // const [isPasswordSet, setIsPasswordSet] = useState(null);
  const { mutate, isLoading, error, isError } = useMutation(
    (data) => setPassword(data),
    {
      onSuccess: (res) => {
        setToken("Token", res?.data?.token);
        navigate("/");
      },
    }
  );

  const userTypeConfig = {
    agents: {
      userType: "agents",
      logo: null,
      heading: "Turn your database into a databank.",
      subHeading: "Login to Upside Agent Dashboard",
      backgroundClass: "auth_aside--bg",
      loginTitle: "Agent Login",
    },
  };

  const config = userTypeConfig.agents;

  const formik = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
      unique_hash: uniqueHash,
    },
    validationSchema: setPasswordSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      mutate(values);
    },
  });

  if (isLoading) {
    return (
      <Loader className="h-screen justify-center items-center flex text-2xl loader-content" />
    );
  }

  return (
    <div className="h-screen flex items-center justify-center p-5">
      <div
        className={clsx(
          "flex max-w-[68.625rem] maxLg:max-w-[calc(100vh-50px)] mx-auto max-h-[40.5rem] maxLg:max-h-full overflow-hidden bg-white relative z-0 w-full shadow-agentsAuthCards rounded-[1.25rem] h-full",
          "relative"
        )}
      >
        <div
          className={clsx(
            "h-full maxLg:absolute maxLg:left-0 maxLg:right-0 maxLg:top-0 w-1/2 maxLg:w-full  auth_aside--bg  "
          )}
        >
          <LoginBannerComponent config={config} />
        </div>

        <div
          className={clsx(
            "w-1/2 flex flex-col justify-center items-center gap-10 maxLg:gap-6 maxLg:w-full z-0 px-14 py-8 maxLg:p-5"
            // { "lg:!justify-end": isError }
          )}
        >
          <div className="w-full text-white text-center lg:hidden">
            <img src={logo} alt="logo" className="lg:h-10 h-8 mx-auto mb-5" />
            <h2 className="text-2xl maxLg:text-xl font-semibold mb-4 maxLg:mb-2">
              Turn your database into a databank.
            </h2>
          </div>
          <form
            className=" bg-white maxLg:max-w-[26.5625rem] w-full maxLg:rounded-lg maxLg:p-5"
            onSubmit={formik.handleSubmit}
          >
            <h2 className="md:text-2xl text-xl text-metallicBlue font-bold block">
              Set Password
            </h2>
            <div>
              <div className="mb-4 mt-6 maxLg:mt-3">
                <FormLabel
                  title="Password"
                  className="text-nickel font-medium mt-2 text-sm maxMd:text-xs"
                />
                <div className="relative mt-1">
                  <FormInput
                    type={passwordShown ? "text" : "password"}
                    name="password"
                    placeholder="Enter password"
                    className={clsx(
                      "border hover:border-metallicBlue focus:border-metallicBlue rounded-lg w-full outline-0 p-2 h-[2.625rem] hover:shadow-agentsAuthInputs focus:shadow-agentsAuthInputs transition text-sm",
                      { "is-error": formik.errors?.password }
                    )}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                  <TogglePasswordVisibilityButton
                    isPasswordShown={passwordShown}
                    togglePasswordVisibility={() =>
                      setPasswordShown(!passwordShown)
                    }
                  />
                </div>
              </div>
              <FormError error={formik.errors.password} />

              <div className="mb-4 mt-6 maxLg:mt-3">
                <FormLabel
                  title="Confirm Password"
                  className="text-nickel font-medium mt-2 text-sm maxMd:text-xs"
                />
                <div className="relative mt-1">
                  <FormInput
                    type={confirmPasswordShown ? "text" : "password"}
                    name="password_confirmation"
                    placeholder="Confirm Password"
                    className={clsx(
                      "border hover:border-metallicBlue focus:border-metallicBlue rounded-lg w-full outline-0 p-2 h-[2.625rem] hover:shadow-agentsAuthInputs focus:shadow-agentsAuthInputs transition text-sm",
                      { "is-error": formik.errors?.password }
                    )}
                    value={formik.values.password_confirmation}
                    onChange={formik.handleChange}
                  />
                  <TogglePasswordVisibilityButton
                    isPasswordShown={confirmPasswordShown}
                    togglePasswordVisibility={() =>
                      setConfirmPasswordShown(!confirmPasswordShown)
                    }
                  />
                </div>
              </div>
              <FormError error={formik.errors.password} />

              {isError && <ServerErrors errors={error} />}

              <FormButton
                type="submit"
                className="!bg-metallicBlue text-white w-full p-3 rounded-md mt-7 maxLg:mt-4 hover:!bg-[#4D6083] transition flex justify-center disabled:pointer-events-none disabled:opacity-[0.6] font-semibold items-center"
                title={"Login"}
                loader={isLoading}
                disabled={isLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SetPasswordForm;
