import React from "react";
import {
  forgotPassword,
  withoutTenantForgotPassword,
} from "../../../../shared/api/agent";
import {
  checkHostNameAndRedirect,
  isUpsideAppTenant,
} from "../../../../shared/helpers/util";
import AgentForgotPasswordPage from "../../../../shared/auth/forgot-password/AgentForgotPasswordPage";

const AgentForgotPassword = () => {
  // Function for condition check
  const shouldRenderLogin = () => {
    return (
      checkHostNameAndRedirect() || !isUpsideAppTenant(window.location.href)
    );
  };

  return (
    <>
      {shouldRenderLogin() ? (
        <AgentForgotPasswordPage
          onSubmit={forgotPassword}
          loginUrl="/agents/login"
        />
      ) : (
        <AgentForgotPasswordPage
          onSubmit={withoutTenantForgotPassword}
          loginUrl="/agents/login"
        />
      )}
    </>
  );
};

export default AgentForgotPassword;
