import axios from "axios";
import {
  ADMIN,
  AGENT,
  HOMEOWNER,
  INVESTOR,
  SELLER,
} from "../helpers/constants";
import { getToken, removeToken } from "../helpers/util";

export const url = process.env.REACT_APP_BACKEND_URL;

const api = axios.create({
  baseURL: `${url}/be/api/v1`,
});

api.interceptors.request.use(
  async (config) => {
    const token = await getToken("Token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      removeToken("Token");

      window.location.replace("/app");
    }
    return Promise.reject(error);
  }
);

export const getUser = () => api.get("/me");

export const changePassword = (data) => api.post("change-password", data);

export const getProperty = (property) => api.get(`/properties/${property}`);

export const getOffer = (offer, isBuyer) =>
  api.get(`/offers/${offer}?is_buyer=${isBuyer}`);

export const getOfferHistory = (offer, isBuyer) =>
  api.get(`${offer}/offer-history?is_buyer=${isBuyer}`);

export const agentGetOffer = (offer) => api.get(`/offers/${offer}`);

export const agentGetOfferHistory = (offer) =>
  api.get(`${offer}/offer-history`);

export const judgeAnOffer = (offer, data) =>
  api.post(`offer/${offer}/status`, data);

export const getSellerById = (id) => api.get(`/chat/sellers/${id}`);

export const getInvestorById = (id) => api.get(`/chat/investors/${id}`);

export const getAgentById = (id) => api.get(`/chat/agents/${id}`);

export const getAdminById = (id) => api.get(`/chat/admins/${id}`);

export const getHomeownerById = (id) => api.get(`/chat/homeowners/${id}`);

export const syncToFirebase = (id, type) => {
  if (type === SELLER) {
    return getSellerById(id);
  } else if (type === INVESTOR) {
    return getInvestorById(id);
  } else if (type === ADMIN) {
    return getAdminById(id);
  } else if (type === AGENT) {
    return getAgentById(id);
  } else if (type === HOMEOWNER) {
    return getHomeownerById(id);
  } else {
    return getAgentById(id);
  }
  // return Promise.reject("Invalid type");
};

export const getStatastics = (type) =>
  api.get("/dashboard", {
    params: {
      type,
    },
  });

export const addImage = (property, data) =>
  api.post(`properties/${property}/images`, data);

export const deleteImage = (property, data) =>
  api.post(`properties/${property}/images`, data);

export const changeMediaOrder = (data) => api.post("change-media-order", data);

export const setPassword = (data) => api.post("set-password", data);

export const verifyBuyerEmail = (data) =>
  api.post("verify-investor-email", data);

export const addConsentForm = (property, data) =>
  api.post(`properties/${property}/consent-form`, data);

export const logout = () => api.post("logout");
export const switchUser = (id) => api.post("emulate", { agent_id: id });

export default api;

export const deleteOfferList = (offerIds) => {
  return api.post(`/offer/${offerIds}/delete`);
};