import React, { useCallback } from "react";

import clsx from "clsx";

import style from "../../../assets/css/homeowner/home.module.css";

import {
  HO_PROPERTY_REQUEST_TYPE_CASH,
  HO_PROPERTY_REQUEST_TYPE_MLS,
} from "../../../shared/helpers/constants";

import buysell from "../../../assets/images/home/buysell.png";

const SellYourHome = ({ setIsOpenPropertiesRequestModal, setRequestType }) => {
  const onClickRequestCashOffer = useCallback(() => {
    setIsOpenPropertiesRequestModal(true);
    setRequestType(HO_PROPERTY_REQUEST_TYPE_CASH);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickMyHomeMls = useCallback(() => {
    setIsOpenPropertiesRequestModal(true);
    setRequestType(HO_PROPERTY_REQUEST_TYPE_MLS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={clsx(
        style.card,
        "p-8 grid xl:grid-cols-4 md:grid-cols-2 gap-9 mt-5"
      )}
    >
      <div>
        <h3 className={style.headingMd}>Ready to sell your home?</h3>
        <p className={clsx("my-2", style.description)}>
          We provide you with multiple options when selling your home so that
          you can make the most informed decison and choose a way to selll your
          home that best fits your situation.
        </p>
      </div>

      <div
        className={clsx(
          "p-5 bg-[#c4c4c412] h-full flex flex-col justify-between",
          style.card
        )}
      >
        <div>
          <h3 className={style.headingMd}>Get a quick cash offer</h3>
          <p className={clsx("my-2", style.description)}>
            This is a great option if you need to sell your home fast and
            without the hassle of showings.
          </p>
        </div>
        <button
          className="dark-button_homehub w-full mt-2 text-sm"
          onClick={onClickRequestCashOffer}
        >
          Request cash offer
        </button>
      </div>

      <div
        className={clsx(
          "p-5 bg-[#c4c4c412] h-full flex flex-col justify-between",
          style.card
        )}
      >
        <div>
          <h3 className={style.headingMd}>List my home on the MLS</h3>
          <p className={clsx("my-2", style.description)}>
            Homes usually sell for the most amount of money when listed and
            marketed correctly on the MLS.
          </p>
        </div>
        <button
          className="dark-button_homehub w-full mt-2 text-sm whitespace-nowrap"
          onClick={onClickMyHomeMls}
        >
          List my home on the MLS
        </button>
      </div>

      <div className="lg:pl-12">
        <img src={buysell} alt="buysell" className="w-full" />
        <p className={clsx("my-2", style.description)}>
          Free move up to $3,000 when you buy and sell with us.
        </p>
      </div>
    </div>
  );
};

export default SellYourHome;
