import * as Yup from "yup";
import { object, string } from "yup";

export const messageValidationSchema = Yup.object({
  name: Yup.string().required("Name is required."),
  email: Yup.string()
    .email("Invalid email address.")
    .required("Email is required."),
  phone: Yup.string().required("Phone is required."),
  message: Yup.string().required("Message is required."),
});

export const homeownerPropertyRequestSchema = object({
  name: string().trim().required("Name is required."),
  email: string()
    .trim()
    .email("Invalid email format.")
    .required("Email is required."),
  phone: string()
    .required("Phone is required.")
    .matches(/^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/, "Invalid phone number."),
});
