import React, { useEffect } from "react";

import { useOnBoarding } from "../../../../../../shared/provider/OnBoardingProvider";
import {
  AGENT_TYPE_SINGLE_AGENT,
  AGENT_TYPE_TEAM,
} from "../../../../../../shared/helpers/constants";

import AgentOnboardingCard from "./AgentOnboardingCard";
import OnboardingTitle from "../../../../../../shared/components/agent-auth-title";

const AgentOnboardingStepOne = () => {
  const { handleNextStep, formik } = useOnBoarding();

  const handleCardClick = (userType) => {
    formik.setFieldValue("type", userType);
    handleNextStep();
  };

  //That we're using for reset form fields when component mounts
  useEffect(() => {
    formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mt-28 maxMd:mt-14 max-w-md mx-auto">
      <h3 className="text-lg text-metallicBlue font-medium block mb-3">
        Agent Signup
      </h3>
      <OnboardingTitle
        title="Welcome to Upside!"
        subTitle="Let’s finish setting up your account. Choose the user type that matches
        you."
      />
      <div className="mt-9">
        <AgentOnboardingCard
          title={"Single Agent"}
          subTitle="If it’s just you or an admin with no other agents, choose this option."
          onClick={() => handleCardClick(AGENT_TYPE_SINGLE_AGENT)}
        />
        <AgentOnboardingCard
          title={"Team Owner"}
          subTitle="Own a real estate team or group? This is for you."
          onClick={() => handleCardClick(AGENT_TYPE_TEAM)}
        />
      </div>
      {/* <p className="mt-14 maxMd:mt-7 text-center text-coolGrey text-base maxSm:text-xs">
        Already have an account?{" "}
        <Link to="/agents/login" className="text-metallicBlue">
          Sign In
        </Link>
      </p> */}
    </div>
  );
};

export default AgentOnboardingStepOne;
