import React, { useCallback } from "react";

import clsx from "clsx";

import style from "../../../assets/css/homeowner/home.module.css";

import { HO_PROPERTY_REQUEST_TYPE_OFFER } from "../../../shared/helpers/constants";
import { numberWithCommas } from "../../../shared/helpers/util";

const EstimatedValue = ({
  setIsOpenPropertiesRequestModal,
  setRequestType,
  propertyData,
}) => {
  const onClickEstimatedValue = useCallback(() => {
    setIsOpenPropertiesRequestModal(true);
    setRequestType(HO_PROPERTY_REQUEST_TYPE_OFFER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className={clsx(style.equityMyHomeCard, "rounded-[5px]")}>
        <h3
          className={clsx(style.headingMd, "leading-[22.4px] tracking-[0.2px]")}
        >
          Estimated equity in my home
        </h3>
        <h2 className="text-[#2FA2B9] font-manrope font-extrabold lg:text-[35px] text-2xl mt-3 leading-[45.5px]">
          {propertyData?.last_estimated_equity !== null
            ? `${numberWithCommas(propertyData.last_estimated_equity)}`
            : "$0"}
        </h2>
      </div>
      <div className="mt-5">
        <button
          className="dark-button_homehub w-full !py-[0.9rem]"
          onClick={onClickEstimatedValue}
        >
          Get an offer on my home
        </button>
        <p className="text-black text-[15px] mt-5 font-normal leading-[21px] tracking-[0.2px]">
          Looking for the quickest way to sell your home? Get a cash offer and
          sell your home with certainty.
        </p>
      </div>
    </div>
  );
};

export default EstimatedValue;
