import React, { useEffect } from "react";
import Login from "../../../../shared/auth/login/Login";
import { login } from "../../../../shared/api/investor";
import { useLocation } from "react-router-dom";
import { useRedirect } from "../../../../store/redirect";

const InvestorLogin = () => {
  const location = useLocation();
  const setRedirect = useRedirect((state) => state.setRedirect);
  const resetRedirect = useRedirect((state) => state.resetRedirect);

  useEffect(() => {
    if (location.pathname === "/buyers/login") {
      resetRedirect();
      return;
    }
    setRedirect({
      isRedirect: true,
      url: `${location.pathname}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Login
      title="Investor Login"
      onSubmit={login}
      forgotPasswordUrl="/buyers/forgot-password"
    />
  );
};

export default InvestorLogin;
