import React from "react";

import { Link } from "react-router-dom";
import clsx from "clsx";
import { object, string } from "yup";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import logo from "../../../assets/images/logotxt.svg";
import Loader from "../../components/loader/Loader";
import FormError from "../../components/form-control/FormError";
import FormInput from "../../components/agent-form-control/FormInput";
import FormLabel from "../../components/agent-form-control/FormLabel";
import FormButton from "../../components/agent-form-control/FormButton";
import buyerBanner from "../../../assets/images/investerlogin.png";
import sellerBanner from "../../../assets/images/sellerLogin.png";

const forgotPasswordSchema = object({
  email: string().email().required(),
});

const ForgotPassword = ({ onSubmit, loginUrl }) => {
  const { mutate, isLoading } = useMutation((contact) => onSubmit(contact), {
    onError: (error, variables, context) => {
      toast.error(error.response.data.message);
    },
    onSuccess: (res) => {
      toast.success(res.data.message);
    },
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      mutate(values);
    },
  });

  const currentUrl = window.location.href;

  const urlParts = currentUrl.split("/").filter((part) => part);

  const secondLastPart = urlParts[urlParts.length - 2] || "";

  const LoginData = {
    sellers: {
      logo: sellerBanner,
      heading: "Enhance Your Property Listings",
      subHeading: "Log in to your Seller Dashboard ",
    },
    buyers: {
      logo: buyerBanner,
      heading: "Access Your Investment Opportunities",
      subHeading: "Log in to your Investor Dashboard  ",
    },
    admin: {
      logo: sellerBanner,
      heading: "Enhance Your Property Listings",
      subHeading: "Log in to your Seller Dashboard ",
    },
    agents: {
      logo: sellerBanner,
      heading: "Enhance Your Property Listings",
      subHeading: "Log in to your Seller Dashboard ",
    },
  };

  if (isLoading) {
    return (
      <Loader className="h-screen justify-center items-center flex text-2xl loader-content" />
    );
  }

  return (
    <>
      <div className="h-screen flex items-center justify-center p-5">
        <div className="flex max-w-[68.625rem] maxLg:max-w-[calc(100vh-50px)] mx-auto max-h-[40.5rem] maxLg:max-h-full overflow-hidden bg-white relative z-0 w-full shadow-agentsAuthCards rounded-[1.25rem] h-full">
          <div className="h-full maxLg:absolute maxLg:left-0 maxLg:right-0 maxLg:top-0 w-1/2 maxLg:w-full ">
            <div className="relative h-full flex items-center justify-center">
              <img
                src={LoginData[secondLastPart]?.logo}
                alt="banner"
                className="w-full h-full object-cover"
              />
              <div className="max-w-[27.25rem] absolute top-11 left-9 text-white maxLg:hidden">
                <img src={logo} alt="logo" className="mb-8" />
                <h2 className="text-2xl font-medium mb-4">
                  {LoginData[secondLastPart]?.heading}
                </h2>
                <p className="text-sm">
                  Log in to manage listings, track leads, and close deals with
                  ease, all in one place.
                </p>
              </div>
            </div>
          </div>
          <div className="w-1/2 flex flex-col justify-center items-center gap-10 maxLg:gap-6 maxLg:w-full z-0 maxLg:p-5">
            <div className="w-full text-white text-center lg:hidden">
              <img src={logo} alt="logo" className="mb-8 maxLg:mx-auto" />
              <h2 className="text-2xl maxLg:text-xl font-semibold mb-4 maxLg:mb-2">
                {LoginData[secondLastPart]?.heading}
              </h2>
              <p className="text-sm maxLg:max-w-[22rem] maxLg:mx-auto">
                Log in to manage listings, track leads, and close deals with
                ease, all in one place.
              </p>
            </div>
            <form
              className="p-14 maxLg:p-5 bg-white maxLg:max-w-[26.5625rem] w-full maxLg:rounded-lg"
              onSubmit={formik.handleSubmit}
            >
              <h2 className="md:text-2xl text-xl text-metallicBlue font-bold block">
                Forgot Password
              </h2>
              <p className="text-[#6B7280] mt-2 text-sm maxMd:text-xs">
                Please enter your email and we will send you a reset link.
              </p>
              <div className="mb-4 mt-6 maxLg:mt-3">
                <FormLabel
                  title="Email"
                  className="text-nickel font-medium mt-2 text-sm maxMd:text-xs"
                />
                <FormInput
                  type="text"
                  name="email"
                  className={clsx(
                    "border hover:border-metallicBlue focus:border-metallicBlue rounded-lg w-full outline-0 p-2 h-[2.625rem] mt-1 hover:shadow-agentsAuthInputs focus:shadow-agentsAuthInputs transition text-sm",
                    {
                      "is-error": formik.errors?.email,
                    }
                  )}
                  placeholder="Enter your email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                <FormError error={formik.errors.email} />
              </div>

              <FormButton
                type="submit"
                className="!bg-metallicBlue text-white w-full p-3 rounded-md mt-7 maxLg:mt-4 hover:!bg-[#4D6083] transition flex justify-center disabled:pointer-events-none disabled:opacity-[0.6] font-semibold items-center"
                title="Send Reset Link"
                loader={isLoading}
                disabled={isLoading}
              />
              <p className="text-sm mt-5 maxLg:mt-3 text-[#6B7280] font-medium cursor-pointer text-center">
                Or{" "}
                <Link
                  to={loginUrl}
                  className="text-steelblue hover:text-[#394E77]"
                >
                  Sign in here
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
