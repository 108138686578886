import React from "react";

import clsx from "clsx";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { homeownerForgotPasswordSchema } from "../validation";
import { homeownerForgotPassword } from "../../../shared/api/homehub";
import FormError from "../../../shared/components/form-control/FormError";

import FormLabel from "../../../shared/components/agent-form-control/FormLabel";
import FormInput from "../../../shared/components/agent-form-control/FormInput";
import FormButton from "../../../shared/components/agent-form-control/FormButton";
import ErrorAlertMessage from "../../../shared/components/server-error/ErrorAlertMessage";

const ForgotPassword = () => {
  const {
    mutate: homeownerForgotPwdMutate,
    isLoading: homeownerForgotPwdIsLoading,
    error: homeownerForgotPwdError,
    isError: homeownerForgotPwdIsError,
  } = useMutation((email) => homeownerForgotPassword(email), {
    onError: (error) => {
      toast.error(error.response.data.message);
    },
    onSuccess: (res) => {
      toast.success(res.data.message);
    },
  });

  const navigate = useNavigate();

  const { handleSubmit, handleChange, errors, values } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: homeownerForgotPasswordSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      homeownerForgotPwdMutate(values);
    },
  });

  return (
    <form
      className="p-14 maxLg:p-5 bg-white maxLg:max-w-[26.5625rem] w-full maxLg:rounded-lg"
      onSubmit={handleSubmit}
    >
      <h2 className="md:text-2xl text-xl text-metallicBlue font-bold block">
        Forgot Password
      </h2>
      <p className="text-coolGrey mt-2 text-sm maxMd:text-xs">
        Please enter your email and we will send you a reset link.
      </p>
      <div className="mb-4 mt-6 maxLg:mt-3">
        <FormLabel
          title="Email"
          className="text-nickel font-medium mt-2 text-sm maxMd:text-xs"
        />
        <FormInput
          className={clsx(
            "border hover:border-metallicBlue focus:border-metallicBlue rounded-lg w-full outline-0 p-2 h-[2.625rem] mt-1 hover:shadow-agentsAuthInputs focus:shadow-agentsAuthInputs transition text-sm",
            {
              "is-error": errors.email,
            }
          )}
          placeholder="Enter your email"
          type="text"
          name="email"
          onChange={handleChange}
          value={values.email}
        />
        <FormError error={errors.email} />
      </div>


      <FormButton
        type="submit"
        className="!bg-metallicBlue text-white w-full p-3 rounded-md mt-7 maxLg:mt-4 hover:!bg-[#4D6083] transition flex justify-center disabled:pointer-events-none disabled:opacity-[0.6] font-semibold items-center"
        title="Send reset link"
        loader={homeownerForgotPwdIsLoading}
        disabled={homeownerForgotPwdIsLoading}
      />

      <p
        onClick={() => navigate(`/homehub/login`)}
        className="text-sm mt-5 maxLg:mt-3 text-[#6B7280] font-medium cursor-pointer text-center"
      >
        Or{" "}
        <span className="text-steelblue hover:text-[#394E77]">
          Sign in here
        </span>
      </p>
      {homeownerForgotPwdIsError && (
        <ErrorAlertMessage className="mt-2" errors={homeownerForgotPwdError} />
      )}
    </form>
  );
};

export default ForgotPassword;
