import React from "react";

import clsx from "clsx";

const Switch = ({ checked, onChange, isPublicRoute }) => {
  return (
    <label
      className={clsx(
        "themeSwitcherTwo relative mt-2 inline-flex select-none items-center cursor-pointer",
        { "cursor-auto": isPublicRoute === true }
      )}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={isPublicRoute === true}
        className="sr-only"
      />

      <span
        className={clsx(
          "slider flex h-7 w-[3.438rem] items-center rounded-full p-1 duration-200",
          checked ? "bg-[#212b36]" : "bg-[#CCCCCE]"
        )}
      >
        <span
          className={clsx("dot h-4 w-4 rounded-full bg-white duration-200", {
            "translate-x-[1.75rem]": checked,
          })}
        ></span>
      </span>
    </label>
  );
};

export default Switch;
