import React from "react";

import { Link } from "react-router-dom";

const Unsubscribe = () => {
  return (
    <>
      <div className={`flex items-center justify-center w-screen h-screen`}>
        <div className="px-40 py-20 bg-white rounded-md shadow-xl maxMd:px-10 mx-12 border-t-[6px] border-steelblue">
          <div className="flex flex-col items-center">
            <h6 className="mb-6 text-2xl font-bold text-center text-gray-800 md:text-3xl">
              You've been unsubscribed.
            </h6>
            <p className="mb-6 text-center text-gray-500 md:text-lg">
              We wan't send you any more emails.
            </p>
            <Link
              to="/"
              className="btn btn-primary inline-block btnNotFound py-[0.55rem] px-[1.75rem]"
            >
              Go home
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Unsubscribe;
