import React from "react";

import { getMessages } from "../api/chat";

function useMessages(chatId) {
  const [messages, setMessages] = React.useState([]);
  const [isLoading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    if (!chatId) return;
    const unsubscribe = getMessages(chatId, setLoading, setMessages, setError);
    return unsubscribe;
  }, [chatId]);

  return { isLoading, messages, error };
}

export default useMessages;
