import React, { useState } from "react";

import clsx from "clsx";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { resetPasswordSchema } from "./validation";
import Loader from "../../components/loader/Loader";
import LoginBannerComponent from "../login/LoginBannerComponent";
import logo from "../../../assets/images/logotxt.svg";
import FormLabel from "../../components/agent-form-control/FormLabel";
import FormInput from "../../components/agent-form-control/FormInput";
import FormButton from "../../components/agent-form-control/FormButton";
import TogglePasswordVisibilityButton from "../../components/password-visibility/TogglePasswordVisibilityButton";
import ErrorAlertMessage from "../../components/server-error/ErrorAlertMessage";

const ResetPassword = ({ onSubmit, loginUrl, email }) => {
  const userTypeConfig = {
    userType: "agents",
    logo: null,
    heading: "Turn your database into a databank.",
    subHeading: "Login to Upside Agent Dashboard",
    backgroundClass: "auth_aside--bg",
    loginTitle: "Agent Login",
  };

  const { token } = useParams();
  const navigate = useNavigate();

  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const { mutate, isLoading, error, isError } = useMutation(
    (contact) => onSubmit(contact),
    {
      onSuccess: (res) => {
        toast.success(res.data.message);
        navigate(loginUrl);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      email: email || "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: resetPasswordSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      values["token"] = token;
      mutate(values);
    },
  });

  if (isLoading) {
    return (
      <Loader className="h-screen justify-center items-center flex text-2xl loader-content" />
    );
  }
  const config = userTypeConfig;

  return (
    <>
      <div className="h-screen flex items-center justify-center p-5">
        <div
          className={clsx(
            "flex max-w-[68.625rem] maxLg:max-w-[calc(100vh-50px)] mx-auto max-h-[40.5rem] maxLg:max-h-full overflow-hidden bg-white relative z-0 w-full shadow-agentsAuthCards rounded-[1.25rem] h-full"
          )}
        >
          <div
            className={clsx(
              "h-full maxLg:absolute maxLg:left-0 maxLg:right-0 maxLg:top-0 w-1/2 maxLg:w-full auth_aside--bg"
            )}
          >
            <LoginBannerComponent config={config} />
          </div>

          <div
            className={clsx(
              "w-1/2 flex flex-col justify-center items-center gap-10 maxLg:gap-6 maxLg:w-full z-0",
              "maxLg:p-5 agent__auth--bg"
              // { "lg:!justify-end": isError }
            )}
          >
            <div className="w-full text-white text-center lg:hidden">
              <img
                src={logo}
                alt="logo"
                className="lg:h-10 h-8 lg:mb-8 maxLg:mb-4 maxLg:mx-auto"
              />
              <h2 className="text-2xl maxLg:text-xl font-semibold mb-4 maxLg:mb-2">
                {config.heading}
              </h2>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="p-14 maxLg:p-5 bg-white maxLg:max-w-[26.5625rem] w-full maxLg:rounded-lg"
            >
              <h2 className="md:text-2xl text-xl text-metallicBlue font-bold block">
                Reset Password
              </h2>
              <div className="mb-4 mt-6 maxLg:mt-3">
                <FormLabel
                  className={
                    "text-nickel font-medium mt-2 text-sm maxMd:text-xs"
                  }
                  title="Email"
                ></FormLabel>
                <FormInput
                  type="text"
                  className={clsx(
                    "border hover:border-metallicBlue focus:border-metallicBlue rounded-lg w-full outline-0 p-2 h-[2.625rem] mt-1 hover:shadow-agentsAuthInputs focus:shadow-agentsAuthInputs transition text-sm email-disable",
                    { "is-error": formik.errors?.email }
                  )}
                  placeholder="Email"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  disabled
                  readOnly
                />
              </div>
              <div className="mb-4 mt-6 maxLg:mt-3">
                <FormLabel
                  className={
                    "text-nickel font-medium mt-2 text-sm maxMd:text-xs"
                  }
                  title="                  New Password
"
                ></FormLabel>

                <div className="relative mt-1">
                  <FormInput
                    type={passwordShown ? "text" : "password"}
                    name="password"
                    placeholder="Enter password"
                    className={clsx(
                      "border hover:border-metallicBlue focus:border-metallicBlue rounded-lg w-full outline-0 p-2 h-[2.625rem] hover:shadow-agentsAuthInputs focus:shadow-agentsAuthInputs transition text-sm",
                      { "is-error": formik.errors?.password }
                    )}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    autoComplete="current-password"
                  />
                  <TogglePasswordVisibilityButton
                    isPasswordShown={passwordShown}
                    togglePasswordVisibility={() =>
                      setPasswordShown(!passwordShown)
                    }
                  />
                </div>
              </div>
              <div className="mb-4 mt-6 maxLg:mt-3">
                <FormLabel
                  className={
                    "text-nickel font-medium mt-2 text-sm maxMd:text-xs"
                  }
                  title="Confirm Password"
                ></FormLabel>
                <div className="relative mt-1">
                  <FormInput
                    type={confirmPasswordShown ? "text" : "password"}
                    name="password_confirmation"
                    placeholder="Enter password"
                    className={clsx(
                      "border hover:border-metallicBlue focus:border-metallicBlue rounded-lg w-full outline-0 p-2 h-[2.625rem] hover:shadow-agentsAuthInputs focus:shadow-agentsAuthInputs transition text-sm",
                      { "is-error": formik.errors?.password_confirmation }
                    )}
                    value={formik.values.password_confirmation}
                    onChange={formik.handleChange}
                    autoComplete="current-password"
                  />
                  <TogglePasswordVisibilityButton
                    isPasswordShown={confirmPasswordShown}
                    togglePasswordVisibility={() =>
                      setConfirmPasswordShown(!confirmPasswordShown)
                    }
                  />
                </div>
              </div>

              <FormButton
                type="button"
                className="!bg-metallicBlue text-white w-full p-3 rounded-md mt-7 maxLg:mt-4 hover:!bg-[#4D6083] transition flex justify-center disabled:pointer-events-none disabled:opacity-[0.6] font-semibold items-center"
                title="Reset Password"
                loader={isLoading}
                disabled={isLoading}
                onClick={formik.handleSubmit}
              />

              {isError && <ErrorAlertMessage className="mt-5" errors={error} />}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
