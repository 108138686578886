import React, { useRef, useState } from "react";

import clsx from "clsx";
import MaskedInput from "react-text-mask";

import Style from "../../../../../../assets/css/agent/auth.module.css";

import { useOnBoarding } from "../../../../../../shared/provider/OnBoardingProvider";
import OnboardingTitle from "../../../../../../shared/components/agent-auth-title";
import FormLabel from "../../../../../../shared/components/agent-form-control/FormLabel";
import FormInput from "../../../../../../shared/components/agent-form-control/FormInput";
import FormError from "../../../../../../shared/components/form-control/FormError";
import { ReactComponent as UploadIcon } from "../../../../../../assets/images/add_image.svg";
import { ReactComponent as EditIcon } from "../../../../../../assets/images/edit_image.svg";

import FormButton from "../../../../../../shared/components/agent-form-control/FormButton";
import {
  allowedProfileImageTypes,
  phoneMask,
} from "../../../../../../shared/api/data";
import { toast } from "react-toastify";

const AgentOnboardingStepFive = () => {
  const { formik, isLoadingAgentSignup } = useOnBoarding();
  const [image, setImage] = useState(null);
  const inputRef = useRef();

  const onUploadPhoto = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  const onPhotoChange = (event) => {
    event.preventDefault();

    let fileObj = event.target.files[0];

    if (!fileObj) {
      return;
    }

    if (!allowedProfileImageTypes.includes(fileObj.type)) {
      toast.error("Please select a valid image (PNG or JPG).");
      return;
    }

    if (fileObj.size > 10 * 1024 * 1024) {
      toast.error("File size must be less than 10MB.");
      return;
    }

    const previewUrl = URL.createObjectURL(fileObj);
    setImage(previewUrl); // Set the image preview

    formik.setFieldValue("logo", fileObj); // Update the form field
  };

  return (
    <div className="max-w-[26.625rem] mx-auto mt-20">
      <OnboardingTitle
        title="Enter your brokerage info"
        subTitle="We want to help keep you in compliance. Please enter your brokerage info and any necessary disclaimers."
      />
      <form className={Style.formLayout} onSubmit={formik.handleSubmit}>
        <div className="mb-4">
          <FormLabel title="Broker Logo" className={Style.formLabel} />
          <input
            type="file"
            style={{ display: "none" }}
            id="photo"
            ref={inputRef}
            name="photo"
            onChange={onPhotoChange}
            accept="image/*"
          />

          <div
            onClick={onUploadPhoto}
            className={clsx(
              "cursor-pointer mt-2 min-h-28 h-full border border-dashed rounded-md w-full flex justify-center items-center flex-col",
              { "!border-solid border-black": image }
            )}
          >
            {image ? (
              <div className="w-full relative">
                <img
                  src={image}
                  alt="Preview"
                  className="w-full h-auto max-h-48 object-cover rounded-md"
                />

                <div className="absolute -top-2 -right-2 border border-black rounded-full p-1 bg-white">
                  <EditIcon className="w-3 h-3 text-white " />
                </div>
              </div>
            ) : (
              <>
                <UploadIcon />
                <p className="text-sm font-normal text-coolGrey">
                  <span className="text-lightSeaGreen">Upload a file </span>or
                  drag and drop
                </p>
                <p className="text-sm font-normal text-coolGrey">
                  PNG, JPG up to 10MB
                </p>
              </>
            )}
          </div>
          <FormError error={formik.errors?.logo} />
        </div>
        <div className="mb-4">
          <FormLabel title="Brokerage Name" className={Style.formLabel} />
          <FormInput
            type={"text"}
            name="brokerage.name"
            placeholder="Enter brokerage name"
            className={clsx(Style.formInput, {
              "is-error": formik.errors.brokerage?.name,
            })}
            value={formik.values.brokerage?.name}
            onChange={formik.handleChange}
          />
          <FormError error={formik.errors.brokerage?.name} />
        </div>
        <div className="mb-4">
          <FormLabel title="Brokerage Phone #" className={Style.formLabel} />
          <MaskedInput
            mask={phoneMask}
            guide={false}
            name="brokerage.phone"
            placeholder="Enter brokerage phone #"
            className={clsx(Style.formInput, {
              "is-error": formik.errors?.brokerage?.phone,
            })}
            value={formik.values.brokerage.phone}
            onChange={formik.handleChange}
          />
          <FormError error={formik.errors?.brokerage?.phone} />
        </div>
        <div className="mb-4">
          <FormLabel title="Brokerage Address" className={Style.formLabel} />
          <FormInput
            type={"text"}
            name="brokerage.address"
            placeholder="Street Address"
            className={clsx(Style.formInput, {
              "is-error": formik.errors?.brokerage?.address,
            })}
            value={formik.values.brokerage.address}
            onChange={formik.handleChange}
          />

          <div className="flex gap-4">
            <FormInput
              type={"text"}
              name="brokerage.city"
              placeholder="City"
              className={clsx(Style.formInput, {
                "is-error": formik.errors?.brokerage?.city,
              })}
              value={formik.values.brokerage.city}
              onChange={formik.handleChange}
            />
            <FormInput
              type={"text"}
              name="brokerage.state"
              placeholder="State"
              className={clsx(Style.formInput, {
                "is-error": formik.errors?.brokerage?.state,
              })}
              value={formik.values.brokerage.state}
              onChange={formik.handleChange}
            />

            <FormInput
              type={"text"}
              name="brokerage.zip_code"
              placeholder="Zip"
              className={clsx(Style.formInput, {
                "is-error": formik.errors?.brokerage?.zip_code,
              })}
              value={formik.values.brokerage.zip_code}
              onChange={formik.handleChange}
            />
          </div>
          <FormError
            error={
              formik.errors?.brokerage?.address ||
              formik.errors?.brokerage?.city ||
              formik.errors?.brokerage?.state ||
              formik.errors?.brokerage?.zip_code
            }
          />
        </div>

        <div className="mb-4">
          <FormLabel
            title="Brokerage Disclaimer"
            className={Style.formLabel}
            optional={true}
          />
          <FormInput
            type={"text"}
            name="brokerage.disclaimer"
            placeholder="Ex:  Each office is independently owned & operated. "
            className={Style.formInput}
            value={formik.values?.brokerage.disclaimer}
            onChange={formik.handleChange}
          />
        </div>
        <FormButton
          type="submit"
          className={clsx(Style.formButton, "flex justify-center items-center")}
          title="Complete"
          loader={isLoadingAgentSignup}
          disabled={isLoadingAgentSignup}
        />
      </form>
    </div>
  );
};

export default AgentOnboardingStepFive;
