import React, { useState } from "react";

import clsx from "clsx";
import { useFormik } from "formik";
import MaskedInput from "react-text-mask";
import { useMutation } from "@tanstack/react-query";

import { phoneMask, sellHomeOptions } from "../../shared/api/data";
import Loader from "../../shared/components/loader/Loader";

import { ReactComponent as UserIcon } from "../../assets/images/homehub-icon/people-icon.svg";
import { ReactComponent as EmailIcon } from "../../assets/images/homehub-icon/email.svg";
import { ReactComponent as PhoneIcon } from "../../assets/images/homehub-icon/phone.svg";
import cross from "../../assets/images/closeSideMenu.svg";
import {
  checkhomeOwnerRequestClick,
  saveHomeOwnerRequest,
} from "../../shared/api/homehub";
import { useUser } from "../../shared/provider/UserProvider";
import { useParams } from "react-router-dom";
import { HO_PROPERTY_REQUEST_TYPE_MLS } from "../../shared/helpers/constants";
import { homeownerPropertyRequestSchema } from "../auth/validation";
import { unmaskPhone } from "../../shared/helpers/util";
import { toast } from "react-toastify";

const RequestHomeValuationModal = ({
  onCloseRequestHomeValuationModal,
  requestType,
  setIsSentRequestModalOpen,
  unique_hash,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [selectedSellHomeOption, setSelectedSellHomeOption] = useState(
    sellHomeOptions[0]
  );

  const {
    mutate: saveHomeOwnerRequestMutate,
    isLoading: isPropertiesRequestLoading,
  } = useMutation((data) => saveHomeOwnerRequest(data), {
    onSuccess: () => {
      onCloseRequestHomeValuationModal();
      setIsSentRequestModalOpen(true);
    },
    onError: (err) => {
      toast.error(err.response.data.message);
    },
  });
  const { user = {} } = useUser() || {};
  const { id } = useParams();
  const { mutate: homeownerRequestClick } = useMutation(
    () => {
      const requestData = {
        type: requestType,
        property_detail_id: id,
      };

      if (unique_hash) {
        requestData.unique_hash = unique_hash;
      }

      return checkhomeOwnerRequestClick(requestData);
    },
    {
      onSuccess: (res) => {},
      onError: (err) => {
        toast.error(err.response?.data?.message);
      },
    }
  );

  const { handleSubmit, values, errors, handleChange } = useFormik({
    initialValues: {
      name: user?.first_name || "",
      email: user?.email || "",
      phone: user?.phone || "",
      sell_home:
        requestType === HO_PROPERTY_REQUEST_TYPE_MLS
          ? selectedSellHomeOption.value
          : "",
    },
    validationSchema: homeownerPropertyRequestSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      const formattedValues = {
        ...values,
        phone: unmaskPhone(values.phone),
      };

      const formData = {
        type: requestType,
        property_detail_id: Number(id),
        ...formattedValues,
      };

      if (unique_hash) {
        formData.unique_hash = unique_hash;
      }

      if (requestType === HO_PROPERTY_REQUEST_TYPE_MLS) {
        formData.sell_home = formattedValues.sell_home;
      }

      saveHomeOwnerRequestMutate(formData);
      homeownerRequestClick();
    },
  });

  return (
    <div className="modal-card px-[3.25rem] pb-[2.65rem] pt-[2.25rem] max-w-[30.32rem] maxSm:w-[calc(100%-1rem)]">
      <div>
        <h2 className="text-[#3B4249C7] text-lg mb-[0.3rem] font-bold">
          Great, we will contact you to prepare a professional home valuation.
        </h2>
        <button
          type="button"
          onClick={onCloseRequestHomeValuationModal}
          className="absolute top-0 right-[1.075rem] text-gray-400 mt-5 mb-4 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center transition-all outline-0"
          data-modal-toggle="staticModal"
        >
          <img src={cross} alt="cross" width={16} />
        </button>
        <p className="text-[13px] font-medium text-blackcoral mt-2">
          Please confirm your infomation.
        </p>
      </div>
      <div className="mt-5">
        <div className="form_input-group flex-col items-start">
          <div className={"flex items-center w-full"}>
            <span>
              <UserIcon />
            </span>
            <input
              name="name"
              placeholder="Name"
              value={values.name}
              onChange={handleChange}
              className={clsx({ "is-error": errors.name })}
            />
          </div>
          <div className={"text-red-600 text-xs font-medium mt-1"}>
            {errors?.name}
          </div>
        </div>
        <div className="form_input-group flex-col items-start">
          <div className={"flex items-center w-full"}>
            <span>
              <EmailIcon />
            </span>
            <input
              name="email"
              placeholder="Email"
              value={values.email}
              onChange={handleChange}
              className={clsx({ "is-error": errors.email })}
            />
          </div>
          <div className={"text-red-600 text-xs font-medium mt-1"}>
            {errors.email}
          </div>
        </div>

        <div className="form_input-group flex-col items-start">
          <div className={"flex items-center w-full"}>
            <span>
              <PhoneIcon />
            </span>
            <MaskedInput
              mask={phoneMask}
              name="phone"
              guide={false}
              placeholder="Phone#"
              value={values.phone}
              onChange={handleChange}
              className={clsx({ "is-error": errors.phone })}
            />
          </div>
          <div className={"text-red-600 text-xs font-medium mt-1"}>
            {errors?.phone}
          </div>
        </div>
        <button
          className="dark-button_homehub w-full mt-4 !py-[0.99rem] !font-medium flex justify-center"
          onClick={handleSubmit}
          disabled={isPropertiesRequestLoading}
        >
          {isPropertiesRequestLoading && <Loader className="mr-3" />}
          Get Professional Valuation
        </button>
      </div>
    </div>
  );
};

export default RequestHomeValuationModal;
