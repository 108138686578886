import React, { useEffect } from "react";
import { updateOnlineStatus } from "../../api/chat";

const Online = ({ isOnline, chatId, role, userId }) => {
  useEffect(() => {
    if (!chatId || !role) return;

    const key = role === "agent" ? userId : "role";

    // Set online status on mount
    updateOnlineStatus(chatId, key, true);

    // Update online status on component unmount
    const handleBeforeUnload = () => {
      updateOnlineStatus(chatId, key, false);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      updateOnlineStatus(chatId, key, false);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [chatId, role, userId]);

  return isOnline ? (
    <p>Online</p>
  ) : (
    <div className="text-gray-400 text-xs leading-[18px] font-medium">
      Offline
    </div>
  );
};

export default Online;
