import { serverTimestamp } from "firebase/firestore";
import React, { createContext, useContext, useRef, useState } from "react";
import { updateMessage } from "../api/chat";
import { includes } from "lodash";

export const MsgContext = createContext(null);

export const ChatMessageProvider = ({ children }) => {
  const [message, setMessage] = useState("");

  const [userChatId, setUserChatId] = useState("");

  const [pressedItems, setPressedItems] = useState([]);
  const [lastMessageId, setLastMessageId] = useState("");

  const timeoutRef = useRef(null);

  const handleMouseDown = (ownMessage, isDelete, id) => {
    if (ownMessage && !isDelete) {
      if (pressedItems?.length === 0) {
        timeoutRef.current = setTimeout(() => {
          setPressedItems(() => [id]);
        }, 1000);
      } else {
        timeoutRef.current = setTimeout(() => {
          setPressedItems(() => [id]);
        }, 1000);
      }
    }
  };
  const handleTouchClick = (ownMessage, isDelete, id) => {
    if (ownMessage && !isDelete) {
      if (pressedItems?.length === 0) {
        return;
      } else {
        setPressedItems([id]);
      }
    }
  };

  const handleMouseUp = () => {
    clearTimeout(timeoutRef.current);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeoutRef.current);
  };

  const onClickMessageDelete = (chatId) => {
    const updateData = {
      message: "Message was deleted",
      timestamp: serverTimestamp(),
      // If you want to update the timestamp as well
    };

    pressedItems?.forEach((messageId) => {
      updateMessage(chatId, messageId, updateData, lastMessageId);
    });
    setPressedItems([]);
  };

  const onClickDeleteWeb = (chatId, messageId) => {
    const updateData = {
      message: "Message was deleted",
      timestamp: serverTimestamp(), // If you want to update the timestamp as well
    };

    updateMessage(chatId, messageId, updateData, lastMessageId);
    setPressedItems([]);
  };

  const isIdPressed = (id) => {
    return includes(pressedItems, id);
  };
  const value = {
    message: message,
    setMessage: setMessage,

    handleMouseDown: handleMouseDown,
    handleMouseUp: handleMouseUp,
    handleMouseLeave: handleMouseLeave,
    isIdPressed: isIdPressed,
    onClickMessageDelete: onClickMessageDelete,
    pressedItems: pressedItems,
    setPressedItems: setPressedItems,
    onClickDeleteWeb: onClickDeleteWeb,
    setLastMessageId: setLastMessageId,

    handleTouchClick: handleTouchClick,
    userChatId: userChatId,
    setUserChatId: setUserChatId,
  };
  return <MsgContext.Provider value={value}>{children}</MsgContext.Provider>;
};

export const useChatMessage = () => {
  return useContext(MsgContext);
};
