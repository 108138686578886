import React, { useState } from "react";

import clsx from "clsx";
import { useFormik } from "formik";
import ReactSelect from "react-select";
import MaskedInput from "react-text-mask";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import {
  dropDownArrowStyle,
  requestModalBtnText,
  unmaskPhone,
} from "../../../../shared/helpers/util";
import {
  checkhomeOwnerRequestClick,
  saveHomeOwnerRequest,
} from "../../../../shared/api/homehub";
import { useUser } from "../../../../shared/provider/UserProvider";
import { phoneMask, sellHomeOptions } from "../../../../shared/api/data";
import { HO_PROPERTY_REQUEST_TYPE_MLS } from "../../../../shared/helpers/constants";
import { homeownerPropertyRequestSchema } from "../validation";
import Loader from "../../../../shared/components/loader/Loader";

import { ReactComponent as UserIcon } from "../../../../assets/images/homehub-icon/people-icon.svg";
import { ReactComponent as EmailIcon } from "../../../../assets/images/homehub-icon/email.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/images/homehub-icon/phone.svg";
import cross from "../../../../assets/images/closeSideMenu.svg";
import { toast } from "react-toastify";

const PropertiesRequestModal = ({
  onClosePropertiesRequestModal,
  setIsSentRequestModalOpen,
  requestType,
  unique_hash,
}) => {
  const [selectedSellHomeOption, setSelectedSellHomeOption] = useState(
    sellHomeOptions[0]
  );

  const { user = {} } = useUser() || {};

  const { id } = useParams();

  const {
    mutate: saveHomeOwnerRequestMutate,
    isLoading: isPropertiesRequestLoading,
  } = useMutation((data) => saveHomeOwnerRequest(data), {
    onSuccess: () => {
      onClosePropertiesRequestModal();
      setIsSentRequestModalOpen(true);
    },
    onError: (err) => {
      toast.error(err.response.data.message);
    },
  });
  const { mutate: homeownerRequestClick } = useMutation(
    () => {
      const requestData = {
        type: requestType,
        property_detail_id: id,
      };

      if (unique_hash) {
        requestData.unique_hash = unique_hash;
      }

      return checkhomeOwnerRequestClick(requestData);
    },

    {
      onSuccess: (res) => {},
    }
  );

  const { handleSubmit, values, errors, setFieldValue, handleChange } =
    useFormik({
      initialValues: {
        name: user?.first_name || "",
        email: user?.email || "",
        phone: user?.phone || "",
        sell_home:
          requestType === HO_PROPERTY_REQUEST_TYPE_MLS
            ? selectedSellHomeOption.value
            : "",
      },
      validationSchema: homeownerPropertyRequestSchema,
      validateOnChange: false,
      onSubmit: (values) => {
        const formattedValues = {
          ...values,
          phone: unmaskPhone(values.phone),
        };

        const formData = {
          type: requestType,
          property_detail_id: Number(id),
          ...formattedValues,
        };

        if (unique_hash) {
          formData.unique_hash = unique_hash;
        }

        if (requestType === HO_PROPERTY_REQUEST_TYPE_MLS) {
          formData.sell_home = formattedValues.sell_home;
        }

        saveHomeOwnerRequestMutate(formData);
        homeownerRequestClick();
      },
    });

  const handleSelectChange = (selected) => {
    setSelectedSellHomeOption(selected);
    setFieldValue("sell_home", selected.value);
  };

  return (
    <div className="modal-card px-[3.25rem] pb-[2.65rem] pt-[2.25rem] max-w-[30.32rem]">
      <div>
        <h2 className="text-[#3B4249C7] text-lg mb-[0.3rem] font-bold leading-[21.78px]">
          Great, lets get started!
        </h2>
        <button
          type="button"
          onClick={onClosePropertiesRequestModal}
          className="absolute top-0 right-[1.075rem] text-gray-400 mt-5 mb-4 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center transition-all outline-0"
          data-modal-toggle="staticModal"
        >
          <img src={cross} alt="cross" width={16} />
        </button>
        <p className="text-[13px] font-medium text-blackcoral">
          Please confirm your infomation.
        </p>
      </div>
      <div className="mt-5">
        <div className="form_input-group flex-col items-start">
          <div className={"flex items-center w-full"}>
            <span>
              <UserIcon />
            </span>
            <input
              name="name"
              placeholder="Name"
              value={values.name}
              onChange={handleChange}
              className={clsx({ "is-error": errors.name })}
            />
          </div>
          <div className={"text-red-600 text-xs font-medium mt-1"}>
            {errors?.name}
          </div>
        </div>
        <div className="form_input-group flex-col items-start">
          <div className={"flex items-center w-full"}>
            <span>
              <EmailIcon />
            </span>
            <input
              name="email"
              placeholder="Email"
              value={values.email}
              onChange={handleChange}
              className={clsx({ "is-error": errors.email })}
            />
          </div>
          <div className={"text-red-600 text-xs font-medium mt-1"}>
            {errors.email}
          </div>
        </div>

        <div className="form_input-group flex-col items-start">
          <div className={"flex items-center w-full"}>
            <span>
              <PhoneIcon />
            </span>
            <MaskedInput
              mask={phoneMask}
              name="phone"
              guide={false}
              placeholder="Phone#"
              value={values.phone}
              onChange={handleChange}
              className={clsx({ "is-error": errors.phone })}
            />
          </div>
          <div className={"text-red-600 text-xs font-medium mt-1"}>
            {errors?.phone}
          </div>
        </div>
        {requestType === HO_PROPERTY_REQUEST_TYPE_MLS && (
          <div className="my-7">
            <h5 className="text-[13px] leading-[17.62px] font-medium text-blackcoral mb-2">
              When do you need to sell your home?
            </h5>
            <ReactSelect
              options={sellHomeOptions}
              defaultValue={sellHomeOptions[0]}
              value={selectedSellHomeOption}
              className={"mt-2 capitalize rounded-[5px]"}
              classNamePrefix={"react-select"}
              styles={dropDownArrowStyle}
              onChange={handleSelectChange}
            />
          </div>
        )}
        <button
          className="dark-button_homehub w-full mt-4 !py-[0.99rem] !font-medium flex justify-center"
          onClick={handleSubmit}
          disabled={isPropertiesRequestLoading}
        >
          {isPropertiesRequestLoading && <Loader className="mr-3" />}
          {requestModalBtnText[requestType] || "Submit"}
        </button>
      </div>
    </div>
  );
};

export default PropertiesRequestModal;
