import React, { useState } from "react";

import ReactSelect from "react-select";
import clsx from "clsx";

import { estimatedHomeValueFilterOptions } from "../../api/data";
import { dropDownArrowStyle, numberWithCommas } from "../../helpers/util";

import EstimatedHomeValueChart from "../../../home-owner/home/EstimatedHomeValueChart";

import style from "../../../assets/css/homeowner/home.module.css";

const EstimatedEquity = ({
  propertyData,
  onClickRequestHomeValuation,
  setRequestType,
}) => {
  const [yearFilter, setYearFilter] = useState(
    estimatedHomeValueFilterOptions[0]
  );

  const handleYearFilterChange = (selected) => {
    setYearFilter(selected);
  };

  return (
    <div
      className={clsx("lg:col-span-2 py-3 maxMd:w-full", style.homeValueCard)}
    >
      <div className="flex gap-[10px] justify-between">
        <h3
          className={clsx(
            style.headingMd,
            "leading-[22.4px] text-base font-semibold"
          )}
        >
          Estimated home value
        </h3>
        <ReactSelect
          options={estimatedHomeValueFilterOptions}
          defaultValue={estimatedHomeValueFilterOptions[0]}
          value={yearFilter}
          className={"capitalize"}
          classNamePrefix={"react-select estimated-year_select"}
          styles={dropDownArrowStyle}
          onChange={handleYearFilterChange}
          isSearchable={false}
        />
      </div>
      <div className="flex gap-[10px] items-center">
        <h1 className="text-yankeesblue font-manrope font-extrabold lg:text-[40px] text-2xl leading-[52px] min-w-[130px]">
          {numberWithCommas(propertyData?.last_estimated_value || "$0")}
        </h1>
        <span className="text-xs text-black md:max-w-[250px] tracking-[0.2px] leading-[16.8px]">
          Your home has gained an estimated{" "}
          {propertyData?.estimated_diff_data?.type !== "Positive" && "-"}
          {numberWithCommas(propertyData?.estimated_diff_data?.difference)}{" "}
          since you purchased it.
        </span>
      </div>
      <EstimatedHomeValueChart
        propertyData={propertyData}
        yearFilter={yearFilter}
        onClickRequestHomeValuation={
          onClickRequestHomeValuation && onClickRequestHomeValuation
        }
      />
    </div>
  );
};

export default EstimatedEquity;
