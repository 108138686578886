import React, { useRef, useState } from "react";

import clsx from "clsx";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useOnClickOutside } from "usehooks-ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import style from "../../../assets/css/homeowner/home.module.css";

import { homeownerMortageRateChange } from "../../../shared/api/homehub";
import {
  TwoDecimalsForPercentage,
  getErrorForMortgageRate,
} from "../../../shared/helpers/util";

const MortgageRate = ({
  propertyData,
  propertyDataRefetch,
  isPublicRoute,
  onClickInterestRatesModal,
}) => {
  const queryClient = useQueryClient();
  const { id } = useParams();

  const [mortgageRate, setMortgageRate] = useState(
    `${TwoDecimalsForPercentage(propertyData?.mortgage_rate)}`
  );
  const mortgageRateTextBoxRef = useRef(null);

  const [mortgageRateEdit, setMortgageRateEdit] = useState(false);
  const [mortgageRateUpdate, setMortgageRateUpdate] = useState(false);

  const { mutate } = useMutation(
    (data) => homeownerMortageRateChange(data, propertyData?.id),
    {
      onSuccess: (res) => {
        propertyDataRefetch();
        setMortgageRate(
          `${TwoDecimalsForPercentage(res?.data?.data?.mortgage_rate)}`
        );
        queryClient.refetchQueries(["get-mortgage-rate", id]);
        toast.success("Mortage Rate updated successfully!");
      },
      onError: (err) => {
        toast.error(err.response.data.message);
      },
    }
  );

  const onOutsideClick = (e) => {
    if (mortgageRateUpdate) {
      const errorMessage = getErrorForMortgageRate(parseFloat(mortgageRate));

      if (errorMessage) {
        toast.error(errorMessage);
        setMortgageRate(propertyData?.mortgage_rate);
      } else {
        mutate({ mortgage_rate: parseFloat(mortgageRate) });
      }
    }

    setMortgageRateEdit(false);
    setMortgageRateUpdate(false);
  };

  useOnClickOutside(mortgageRateTextBoxRef, onOutsideClick);

  const onChangeMortgageRate = (e) => {
    setMortgageRateUpdate(true);

    setMortgageRate(parseFloat(e.target.value).toFixed(2));
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setMortgageRateUpdate(true);
      onOutsideClick();
    }
  };

  return (
    <>
      <div className={clsx("px-6 py-[1.15rem]", style.card)}>
        <h3 className={style.headingMd}>My mortgage rate</h3>
        <p className={clsx("mt-2 mb-[0.7rem] text-[13px]", style.description)}>
          It’s important to know your mortgage rate and if decreasing rates make
          sense for you to refinance.
        </p>
        <div
          className={clsx(
            "flex p-3 max-w-[254px] gap-3 items-center bg-[#c4c4c412] h-[92px] justify-between",
            style.card
          )}
        >
          <p className="text-[13px] text-black font-inter-medium w-[106px]">
            My estimated mortgage rate is
          </p>
          <div>
            {isPublicRoute !== true && mortgageRateEdit ? (
              <div className={clsx("max-w-[120px]", style.monthlyPayment)}>
                <input
                  ref={mortgageRateTextBoxRef}
                  className="w-full px-2 py-1"
                  defaultValue={mortgageRate}
                  onChange={(e) => onChangeMortgageRate(e)}
                  onKeyDown={handleKeyPress}
                  disabled={isPublicRoute === true}
                />
                <span>%</span>
              </div>
            ) : (
              <h3
                className="text-[40px] leading-10 inter-bold"
                onClick={() => setMortgageRateEdit(true)}
              >
                {`${mortgageRate}%` || "0"}
              </h3>
            )}
            <span className="text-aurometalsaurus text-xs font-semibold">
              30-Year Fixed
            </span>
          </div>
        </div>
        <button
          className="mt-5 block text-[#2FA2B9] font-inter-medium text-[15px] font-medium w-fit"
          onClick={onClickInterestRatesModal}
        >
          See today’s rates
        </button>
      </div>
    </>
  );
};

export default MortgageRate;
