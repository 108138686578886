import React, { useState } from "react";

import clsx from "clsx";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

import { homeownerResetPasswordSchema } from "../validation";
import { homeownerResetPassword } from "../../../shared/api/homehub";

import FormError from "../../../shared/components/form-control/FormError";
import TogglePasswordVisibilityButton from "../../../shared/components/password-visibility/TogglePasswordVisibilityButton";
import FormInput from "../../../shared/components/agent-form-control/FormInput";
import FormLabel from "../../../shared/components/agent-form-control/FormLabel";
import FormButton from "../../../shared/components/agent-form-control/FormButton";
import ErrorAlertMessage from "../../../shared/components/server-error/ErrorAlertMessage";

const ResetPassword = () => {
  const navigate = useNavigate();

  const { email, token } = useParams();
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const {
    mutate: homeownerResetPwdMutate,
    isLoading: homeownerResetPwdIsLoading,
    error: homeownerResetPwdError,
    isError: homeownerResetPwdIsError,
  } = useMutation((contact) => homeownerResetPassword(contact), {
    onSuccess: (res) => {
      toast.success(res.data.message);
      navigate("/homehub/login");
    },
  });

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      email: email || "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: homeownerResetPasswordSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      values["token"] = token;
      homeownerResetPwdMutate(homeownerResetPasswordSchema.cast(values));
    },
  });

  return (
    <div
      className={
        " flex flex-col justify-center items-center gap-10 maxLg:gap-6 maxLg:w-full z-0 px-14 py-8 maxLg:p-5 w-full"
      }
    >
      <form
        className="bg-white maxLg:max-w-[26.5625rem] w-full rounded-lg maxLg:p-5"
        onSubmit={handleSubmit}
      >
        <h1 className="md:text-2xl text-xl text-metallicBlue font-bold block">
          Reset Your Password
        </h1>
        <div className="mb-4 mt-6 maxLg:mt-3 ">
          <FormLabel
            title="Email"
            className="text-nickel font-medium mt-2 text-sm maxMd:text-xs"
          />
          <div className="relative top-1/2 w-full">
            <FormInput
              type="text"
              name="email"
              className={clsx(
                "border hover:border-metallicBlue focus:border-metallicBlue rounded-lg w-full outline-0 p-2 h-[2.625rem] mt-1 hover:shadow-agentsAuthInputs focus:shadow-agentsAuthInputs transition text-sm",
                { "is-error": errors?.email }
              )}
              placeholder="Email"
              onChange={handleChange}
              value={values.email}
            />
            <FormError error={errors.email} />
          </div>
        </div>

        <div className="mb-4  maxLg:mt-3 ">
          <FormLabel
            title="Password"
            className="text-nickel font-medium mt-2 text-sm maxMd:text-xs"
          />
          <div className=" relative top-1/2 w-full">
            <FormInput
              type={passwordShown ? "text" : "password"}
              name="password"
              placeholder="Password"
              onChange={handleChange}
              className={clsx(
                "border hover:border-metallicBlue focus:border-metallicBlue rounded-lg w-full outline-0 p-2 h-[2.625rem] mt-1 hover:shadow-agentsAuthInputs focus:shadow-agentsAuthInputs transition text-sm",
                { "is-error": errors?.password }
              )}
              value={values.password}
            />
            <TogglePasswordVisibilityButton
              isPasswordShown={passwordShown}
              togglePasswordVisibility={() => setPasswordShown(!passwordShown)}
            />
          </div>
          <FormError error={errors.password} />
        </div>
        <div className="mb-4  maxLg:mt-3 ">
          <FormLabel
            title="Confirm Password "
            className="text-nickel font-medium mt-2 text-sm maxMd:text-xs"
          />
          <div className=" relative top-1/2 w-full">
            <FormInput
              type={confirmPasswordShown ? "text" : "password"}
              name="password_confirmation"
              placeholder="Password Confirmation"
              onChange={handleChange}
              className={clsx(
                "border hover:border-metallicBlue focus:border-metallicBlue rounded-lg w-full outline-0 p-2 h-[2.625rem] mt-1 hover:shadow-agentsAuthInputs focus:shadow-agentsAuthInputs transition text-sm",
                { "is-error": errors?.password_confirmation }
              )}
              value={values.password_confirmation}
            />
            <TogglePasswordVisibilityButton
              isPasswordShown={confirmPasswordShown}
              togglePasswordVisibility={() =>
                setConfirmPasswordShown(!confirmPasswordShown)
              }
            />
            <FormError error={errors.password_confirmation} />
          </div>
        </div>

        <FormButton
          type="submit"
          className="!bg-metallicBlue text-white w-full p-3 rounded-md mt-7 maxLg:mt-4 hover:!bg-[#4D6083] transition flex justify-center disabled:pointer-events-none disabled:opacity-[0.6] font-semibold items-center"
          title="Reset Password"
          loader={homeownerResetPwdIsLoading}
          disabled={homeownerResetPwdIsLoading}
        />
        {homeownerResetPwdIsError && (
          <ErrorAlertMessage
            className={"mt-5 lg:hidden"}
            errors={homeownerResetPwdError}
          />
        )}
      </form>
      {homeownerResetPwdIsError && (
        <ErrorAlertMessage
          className={"maxLg:hidden"}
          errors={homeownerResetPwdError}
        />
      )}
    </div>
  );
};

export default ResetPassword;
