import React from "react";
import ButtonLoader from "../loader/ButtonLoader";

const FormButton = ({ type, className, title, onClick, loader, disabled }) => {
  return (
    <button
      type={type}
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
      {loader && <ButtonLoader className="ml-2" />}
    </button>
  );
};

export default FormButton;
