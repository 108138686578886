import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getTenant } from "../../api/agent";
import Loader from "../loader/Loader";

import brandLogo from "../../../assets/images/logo4.svg";
import clsx from "clsx";

const AppLabel = ({ classNameLogo, classNameLoader, classNameTextLogo }) => {
  const { data: getTenantData, isLoading: isLoadingTenant } = useQuery(
    ["get-tenant"],
    () => getTenant(),
    {
      select: (res) => {
        return res.data?.data;
      },
    }
  );
  return (
    <>
      {isLoadingTenant ? (
        <Loader className={clsx("flex justify-center", classNameLoader)} />
      ) : (
        <>
          {getTenantData ? (
            <>
              {getTenantData?.logo ? (
                <img
                  className={clsx(
                    "md:object-contain object-contain md:w-[137px] h-[80px] maxMd:mx-auto w-[200px] block maxMd:w-40 mx-auto",
                    classNameLogo
                  )}
                  src={getTenantData?.logo}
                  alt="logo"
                />
              ) : (
                <div
                  className={clsx(
                    "flex items-center gap-2  mb-6 justify-center",
                    classNameTextLogo
                  )}
                >
                  <div className="w-[45px] h-[45px] object-cover rounded-[50%] bg-steelblue flex justify-center text-white text-[30px] items-center">
                    <p className="text-3xl">
                      {getTenantData?.display_name?.charAt(0).toUpperCase()}
                    </p>
                  </div>
                  <h2 className="text-3xl text-steelblue title_lg capitalize">
                    {getTenantData?.display_name}
                  </h2>
                </div>
              )}
            </>
          ) : (
            <img src={brandLogo} alt="brandLogo" className="mx-auto" />
          )}
        </>
      )}
    </>
  );
};

export default AppLabel;
