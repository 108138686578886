import React from "react";

import { useNavigate } from "react-router-dom";

import { ReactComponent as MortgageRateIcon } from "../../../../assets/images/homehub-icon/mortgage-rates.svg";
import cross from "../../../../assets/images/closeSideMenu.svg";

const MortgageRatesModal = ({
  propertyData,
  setMortgageRateModal,
  assigneeData,
}) => {
  const navigate = useNavigate();

  const onClickModalClose = () => {
    setMortgageRateModal(false);
    navigate(
      `/property/${assigneeData?.home_owner_primary_property_id}/details`,
      { replace: true }
    );
  };

  return (
    <div className="modal-card flex items-center flex-col text-center w-full p-14 max-w-[37.5rem]">
      <div className="flex items-center flex-col text-center">
        <button
          type="button"
          className="absolute top-0 right-3.5 text-gray-400 mt-4 mb-4 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center transition-all outline-0"
          data-modal-toggle="staticModal"
          onClick={() => onClickModalClose()}
        >
          <img src={cross} alt="cross" width={16} />
        </button>
        <MortgageRateIcon />
        <h2 className="font-inter-semiBold text-jacarta text-lg mt-6 mb-4">
          Great news!
        </h2>
        <h3 className="font-medium text-jacarta text-lg mb-5 px-24">
          Mortgage rates have dropped to your target percentage of{" "}
          {propertyData?.mortgage_rate_alert?.percent}%.
        </h3>
        <p className="text-jacarta text-sm tracking-[0.088rem]">
          You could save an estimated <b>$275/month </b> with this new rate.
        </p>
        <button className="dark-button !bg-jacarta tracking-[0.088rem] mt-10">
          Lower my mortgage payment now
        </button>
      </div>
    </div>
  );
};

export default MortgageRatesModal;
