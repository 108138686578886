import { get } from "lodash";

const config = {
  maximum_bed_limit: 10,
  maximum_bath_limit: 10,

  limit: {
    1: "One",
    2: "Two",
    3: "Three",
    4: "Four",
    5: "Five",
    6: "Six",
    7: "Seven",
    8: "Eight",
    9: "Nine",
    10: "Ten",
  },

  type_of_property: {
    1: "Single Family",
    2: "Condo",
    3: "Townhouse",
    4: "Multi-Family",
    5: "Land",
  },

  garage_type: {
    1: "No Garage",
    2: "1-Car",
    3: "2-Car",
    4: "3-Car",
    5: "Carport",
    6: "Parking Deck",
  },

  lot_size: {
    1: 0.4,
    2: 0.5,
    3: 1,
    4: 5,
    5: 10,
    6: 20,
    7: 30,
    8: 40,
    9: 50,
  },

  property_status_dropdown: {
    1: "DRAFT",
    2: "ACTIVE",
  },

  investor_stats: {
    active_offers: {
      type: "static",
      name: "Active Offers",
    },
    pending_properties_count: {
      type: "static",
      name: "Pending",
    },
    closed_properties_count: {
      type: "static",
      name: "Closed",
    },
    total_offers_count: {
      type: "static",
      name: "Total Offers",
    },
    offers_to_close_percent: {
      type: "percent",
      name: "Offer to Close %",
    },
    pending_volume: {
      type: "amount",
      name: "Pending Volume",
    },
    closed_volume: {
      type: "amount",
      name: "Closed Volume",
    },
    average_purchase_percent: {
      type: "percent",
      name: "Avg. Purchase %",
    },
    average_purchase_price: {
      type: "amount",
      name: "Avg. Purchase Price",
    },
    price_range: {
      type: "range",
      name: "Price Range",
    },
  },

  //config.seller_stats.sellerStats[key]

  seller_stats: {
    active_properties: {
      type: "static",
      name: "Active Property",
    },
    pending_properties: {
      type: "static",
      name: "Pending",
    },
    closed_properties: {
      type: "static",
      name: "Closed",
    },
    offers_count: {
      type: "static",
      name: "Offers",
    },
    closed_properties_percent: {
      type: "percent",
      name: "Close %",
    },
    pending_volume: {
      type: "amount",
      name: "Pending Volume",
    },
    closed_volume: {
      type: "amount",
      name: "Closed Volume",
    },
    average_sales_price: {
      type: "amount",
      name: "Avg. Sales Price",
    },
    list_sales_price: {
      type: "percent",
      name: "List Price/Sales Price",
    },
    price_range: {
      type: "range",
      name: "Price Range",
    },
  },

  offer_stats: {
    properties_with_offer: {
      type: "static",
      name: "Properties with Offers",
    },
    offers: {
      type: "static",
      name: "Offers",
    },
    closed_offers: {
      type: "static",
      name: "Closed",
    },
    offer_to_close_percent: {
      type: "percent",
      name: "Offer to Close %",
    },
    pending_volume: {
      type: "amount",
      name: "Pending Volume",
    },
    closed_volume: {
      type: "amount",
      name: "Closed Volume",
    },
    average_offers_per_property: {
      type: "static",
      name: "Avg. Offers Per Property",
    },
    average_sales_price: {
      type: "amount",
      name: "Avg. Sales Price",
    },
    price_range: {
      type: "range",
      name: "Price Range",
    },
    average_purchase_percent: {
      type: "percent",
      name: "Avg. Purchase %",
    },
  },

  getPropertyTagColor: {
    ACTIVE: "bg-oceanGreen text-white",
    DRAFT: "bg-steelblue text-white",
    PENDING: "bg-pastelorange text-white",
    SOLD: "bg-salmonpink text-white",
    INACTIVE: "bg-cinnabar text-white",
  },

  getOfferTagColor: {
    SUBMITTED: "bg-darkturquoise",
    COUNTERED: "bg-steelblue",
    ACCEPTED: "bg-oceanGreen",
    CANCELLED: "bg-silversand text-white",
    DECLINED: "bg-cinnabar",
    CLOSED: "!bg-salmonpink",
    HOMEOWNER_COUNTERED: "!bg-pastelorange",
    APPROVED: "bg-arsenic",
    HOMEOWNER_ACCEPTED: "bg-pictonBlue",
    HOMEOWNER_DECLINED: "bg-lightVenetianRed",
    RECEIVED: "bg-darkturquoise",
    MODIFIED: "bg-blue-300",
  },
  getOfferTagValue: {
    SUBMITTED: "SUBMITTED",
    COUNTERED: "COUNTERED",
    ACCEPTED: "ACCEPTED",
    CANCELLED: "CANCELLED",
    DECLINED: "DECLINED",
    CLOSED: "CLOSED",
    APPROVED: "APPROVED",
    HOMEOWNER_COUNTERED: "HO COUNTERED",
    HOMEOWNER_ACCEPTED: "HO ACCEPTED",
    HOMEOWNER_DECLINED: "HO DECLINED",
    RECEIVED: "RECEIVED",
    MODIFIED: "MODIFIED",
  },

  time_filters: {
    ytd: "YTD",
    "7days": "Last 7 Days",
    "30days": "Last 30 Days",
    "3months": "Last 3 Months",
    "6months": "Last 6 Months",
    "9months": "Last 9 Months",
    "12months": "Last 12 Months",
  },
};

export function getPropertyType(propertyNumber) {
  return get(config?.type_of_property, propertyNumber, "-");
}
export function getGarageType(garageNumber) {
  return get(config.garage_type, garageNumber, "-");
}

export default config;
