import Modal from "react-modal";

import clsx from "clsx";

const ModalPortal = ({ open, children, className }) => {
  Modal.setAppElement("#root");

  if (!open) {
    return null;
  }

  return (
    <Modal
      isOpen={open}
      overlayClassName={clsx("overlay", className)}
      className="w-full"
    >
      {children}
    </Modal>
  );
};

export default ModalPortal;
