import React from "react";

import { useFormik } from "formik";
import clsx from "clsx";

import FormError from "../../../../shared/components/form-control/FormError";
import { messageValidationSchema } from "../validation";

import cross from "../../../../assets/images/closeSideMenu.svg";
import { ReactComponent as EmailIcon } from "../../../../assets/images/homehub-icon/email.svg";
import { ReactComponent as UserIcon } from "../../../../assets/images/homehub-icon/people-icon.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/images/homehub-icon/phone.svg";

const MessageModal = ({ setMessageModal, assigneeData }) => {
  const { handleSubmit, handleChange, values, errors, resetForm } = useFormik({
    initialValues: {
      name: assigneeData?.first_name,
      email: assigneeData?.email,
      phone: assigneeData?.phone,
      message: "",
    },
    validationSchema: messageValidationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      resetForm();
    },
  });

  const onClickModalClose = () => {
    setMessageModal(false);
  };

  return (
    <div className="modal-card">
      <button
        type="button"
        className="absolute top-0 right-3.5 text-gray-400 mt-4 mb-4 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center transition-all outline-0"
        data-modal-toggle="staticModal"
        onClick={() => onClickModalClose()}
      >
        <img src={cross} alt="cross" width={16} />
      </button>

      <p className="call_modal_heading">Have a question?!</p>

      <div className="inputGroup">
        <div className="authInput_homehub relative">
          <UserIcon className={"absolute left-6"} />
          <input
            type="text"
            name="name"
            placeholder="Name"
            onChange={handleChange}
            value={values.name}
            className={clsx({
              "is-error": errors.name,
            })}
          />
        </div>
        <FormError error={errors.name} />
      </div>

      <div className="inputGroup">
        <div className="authInput_homehub relative">
          <EmailIcon className={"absolute left-6"} />
          <input
            type="text"
            name="email"
            placeholder="Email"
            onChange={handleChange}
            value={values.email}
            className={clsx({
              "is-error": errors.email,
            })}
          />
        </div>
        <FormError error={errors.email} />
      </div>

      <div className="inputGroup">
        <div className="authInput_homehub relative">
          <PhoneIcon className={"absolute left-6"} />
          <input
            type="text"
            name="phone"
            placeholder="Phone"
            onChange={handleChange}
            value={values.phone}
            className={clsx({
              "is-error": errors.phone,
            })}
          />
        </div>
        <FormError error={errors.phone} />
      </div>
      <div className="inputGroup mb-0">
        <textarea
          type="text"
          name="message"
          placeholder="Your message here.."
          onChange={handleChange}
          value={values.message}
          className={clsx(
            {
              "is-error": errors.message,
            },
            "message_inputbox"
          )}
        />
        <FormError error={errors.message} />
      </div>

      <button
        type="button"
        className="copy_button mt-[37px]"
        onClick={handleSubmit}
      >
        Send message
      </button>
    </div>
  );
};

export default MessageModal;
