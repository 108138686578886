
import logo from "../../../assets/images/logotxt.svg";


const BannerContent = ({ config, logo }) => {
    if (!config) return null; // Add safety check



    if (config.logo) {
        return (
            <>
                <img
                    src={config.logo}
                    alt="banner"
                    className="w-full h-full object-cover"
                />
                <div className="max-w-[27.25rem] absolute top-11 left-9 text-white maxLg:hidden">
                    <img src={logo} alt="logo" className="mb-8" />
                    <h2 className="text-2xl font-medium mb-4">{config.heading}</h2>
                    <p className="text-sm">
                        Log in to manage listings, track leads, and close deals with ease, all in one place.
                    </p>
                </div>
            </>
        );
    }

    return (
        <div className="max-w-[26.25rem] text-white maxLg:hidden mb-[5.625rem]">
            <img src={logo} alt="logo" className="lg:h-10 h-8 mx-auto mb-5" />
            <h2 className="text-2xl font-medium mb-4">{config.heading}</h2>
        </div>
    );
};

const LoginBannerComponent = ({ config }) => {
    return (
        <div className="relative h-full flex items-center justify-center">
            <BannerContent config={config} logo={logo} />
        </div>
    );
};
export default LoginBannerComponent;