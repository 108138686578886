import banner from "../../assets/images/homeowner-banner.png";
import AppLabel from "../../shared/components/white-label/AppLabel";

const withAuthPagesLayoutHOC = (ComposedComponent) => {
  return (
    <>
      <div className="h-screen flex items-center justify-center p-5">
        <div className="flex max-w-[68.625rem] maxLg:max-w-[calc(100vh-50px)] mx-auto max-h-[40.5rem] maxLg:max-h-full overflow-hidden bg-white relative z-0 w-full shadow-agentsAuthCards rounded-[1.25rem] h-full">
          <div className="h-full maxLg:absolute maxLg:left-0 maxLg:right-0 maxLg:top-0 w-1/2 maxLg:w-full ">
            <div className="relative h-full flex items-center justify-center">
              <img
                src={banner}
                alt="banner"
                className="w-full h-full object-cover"
              />
              <div className="max-w-[26.25rem] absolute top-11 left-9 text-white maxLg:hidden">
                <AppLabel
                  classNameLogo={"!m-0 !mb-14"}
                  classNameLoader={"mb-[110px] !justify-start"}
                  classNameTextLogo={"mb-[90px] !justify-start"}
                />
                <h2 className="text-2xl font-semibold mb-4">
                  Manage Your Property Anytime
                </h2>
                <p className="text-sm">
                  Log in to manage listings, track leads, and close deals with
                  ease, all in one place.
                </p>
              </div>
            </div>
          </div>
          <div className="w-1/2 flex flex-col justify-center items-center gap-10 maxLg:gap-6 maxLg:w-full z-0 maxLg:p-5 maxLg:bg-shadowedGrey agent__auth--bg">
            <div className="w-full text-white text-center lg:hidden">
              <AppLabel classNameLogo={"!mb-6"} />
              <h2 className="text-2xl maxLg:text-xl font-semibold mb-4 maxLg:mb-2">
                Manage Your Property Anytime
              </h2>
              <p className="text-sm maxLg:max-w-[22rem] maxLg:mx-auto">
                Log in to manage listings, track leads, and close deals with
                ease, all in one place.
              </p>
            </div>
            <ComposedComponent />
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuthPagesLayoutHOC;
