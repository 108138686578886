import { mixed, object, string } from "yup";

export const agentSignupStepTwoSingleAgentSchema = (shouldValidateDomain) => {
  let baseSchema = object({
    first_name: string().trim().required("First name is required."),
    last_name: string().trim().required("Last name is required."),
    email: string()
      .trim()
      .matches(
        /^[a-zA-Z0-9+]+(?:\.[a-zA-Z0-9+]+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        {
          message: "Invalid email format.",
        }
      )
      .required("Email is required."),
    phone: string()
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Invalid phone format.")
      .required("Phone number is required."),
    password: string()
      .trim()
      .required("Password is required.")
      .min(8, "Password must be at least 8 characters long."),
    password_confirmation: string()
      .trim()
      .required("Confirm password is required.")
      .min(8, "Confirm password must be at least 8 characters long.")
      .test(
        "password-match.",
        "Confirm Password do not match.",
        function (value) {
          return this.parent.password === value;
        }
      ),
  });

  if (shouldValidateDomain) {
    baseSchema = baseSchema.shape({
      domain: string()
        .trim()
        .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, {
          message: "Invalid subdomain.",
          excludeEmptyString: true,
        })
        .max(50, "Subdomain must be at most 50 characters.")
        .required("Subdomain is required.")
        .test(
          "no-spaces",
          "Subdomain cannot contain spaces.",
          (value) => value && !/\s/.test(value)
        ),
    });
  }

  return baseSchema;
};

export const agentSignupStepThreeSchema = object({
  otp: string()
    .matches(/^\d+$/, "Verification Code must contain only numbers.")
    .trim()
    .required("Verification Code is required."),
});

export const agentSignupStepFourSchema = object({
  team_name: string().trim().required("Team name is required."),
  domain: string()
    .trim()
    .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, {
      message: "Invalid subdomain",
      excludeEmptyString: true,
    })
    .max(50, "Subdomain must be at most 50 characters")
    .required("Subdomain is required.")
    .test(
      "no-spaces",
      "Subdomain cannot contain spaces.",
      (value) => value && !/\s/.test(value)
    ),
  team_phone: string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Invalid phone number format.")
    .required("Team phone number is required."),
});

export const agentSignupStepFiveSchema = object({
  brokerage: object().shape({
    name: string().trim().required("Brokerage name is required."),
    phone: string()
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Invalid phone number format.")
      .required("Brokerage phone number is required."),
    city: string()
      .trim()
      .matches(/^[A-Za-z\s]+$/, "Enter valid address.")
      .required("Address is required."),
    state: string()
      .trim()
      .matches(/^[A-Za-z\s]+$/, "Enter valid address.")
      .required("Address is required."),
    zip_code: string()
      .matches(/^\d{5}$/, "Zip code must be 5 digits.")
      .trim()
      .required("Address is required."),
    address: string().trim().required("Address is required."),
  }),
  logo: mixed()
    .test(
      "fileType",
      "Logo must be an image file (JPG, PNG, or GIF).",
      (value) =>
        !value || // If value is not provided, skip this validation
        (value instanceof File &&
          ["image/jpeg", "image/png", "image/gif"].includes(value.type))
    )
    .test(
      "fileSize",
      "Logo file size must be less than 2MB.",
      (value) =>
        !value || // If value is not provided, skip this validation
        (value instanceof File && value.size <= 2 * 1024 * 1024) // 2MB in bytes
    )
    .required("Brokerage logo is required."),
});
