import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useWindowSize } from "usehooks-ts";
import { useQuery } from "@tanstack/react-query";

import styles from "../../../assets/css/chat-messages.module.css";
import useChat from "../../hooks/useChat";
import useMessages from "../../hooks/useMessages";

import { syncToFirebase } from "../../api";
import { updateByChatId } from "../../api/chat";

import ImageUploading from "./ImageUploading";
import Loader from "../loader/Loader";
import NotFound from "../not-found/NotFound";
import Online from "./Online";

import {
  getChatId,
  getCurrentEntityFromRole,
  getOppMsgEntity,
} from "../../helpers/util";
import PublicChatInput from "./PublicChatInput";
import PublicChatConversation from "./PublicChatConversation";

import cross from "../../../assets/images/closeSideMenu.svg";
import usePublicUploadFile from "../../hooks/usePublicUploadFile";

const PublicChatMessages = ({ homehubData, propertyData, onClose, crtEntity, optEntity }) => {


  const [chatId, setChatId] = useState(null);

  const { width, height } = useWindowSize();


  const { oppEntity, getUserByIdApi } = getOppMsgEntity(optEntity);
  const currentEntity = getCurrentEntityFromRole(crtEntity);

  const { isLoading: isLoadingInvestor, isError: isErrorInvestor } = useQuery(
    ["get-investor-by-id", propertyData?.agent?.id],
    () => getUserByIdApi(propertyData?.agent?.id),
    {
      cacheTime: 0,
      keepPreviousData: false,
      onSuccess: async (res) => {
        const data = res.data.data;


        const seller = {
          id: homehubData.id,
          name: `${homehubData.first_name} ${homehubData.last_name}`,
          isOnline: true,
          lastMessage: null,
          lastMessageTimestamp: null,
          unreadCount: 0,
        };

        const oppUser = {
          id: parseInt(propertyData?.agent?.id),
          name: `${data.first_name} ${data.last_name}`,
          isOnline: false,
          lastMessage: null,
          lastMessageTimestamp: null,
          unreadCount: 0,
        };


        const fetchedChatId = await getChatId(
          seller,
          oppUser,
          currentEntity,
          oppEntity
        );

        setChatId(fetchedChatId);
      },
    }
  );

  //   const {  chats: list } = useChatList(homehubData.id, crtEntity);

  // const chats = useMemo(() => {
  //   return list.map((row) => {
  //     let oppositeMessenger = getOppositeMessenger(row, crtEntity);
  //     return {
  //       chatId: row.id,
  //       user_type: oppositeMessenger,
  //       name: row[oppositeMessenger]?.name,
  //       ...row[oppositeMessenger],
  //       lastMessage: row.lastMessage,
  //       lastMessageTimestamp: row.lastMessageTimestamp,
  //     };
  //   });
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [list]);
  // console.log(chats)

  const {
    chat,
    isLoading: isLoadingChat,
    error: chatError,
    oppositeMessenger,
  } = useChat(chatId, crtEntity);

  const { messages } = useMessages(chatId || null);

  useQuery(
    ["getUser", oppositeMessenger, chat?.id],
    () => syncToFirebase(chat[oppositeMessenger]?.id, oppositeMessenger),
    {
      cacheTime: 0,
      keepPreviousData: false,
      enabled: !!chat,
      onSuccess: (res) => {
        const data = res.data.data;
        updateByChatId(chat.id, {
          [`${oppositeMessenger}.name`]: `${data.first_name} ${data.last_name}`,
          [`${oppositeMessenger}.avatar`]: data.avatar,
        });
      },
    }
  );




  const {
    isLoading: uploadLoading,
    error: uploadError,
    progress,
    sendImage,
  } = usePublicUploadFile(homehubData.id);


  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);


  useEffect(() => {
    if (chatId && messages.length && oppositeMessenger) {
      updateByChatId(chatId, {
        [`${oppositeMessenger}.unreadCount`]: 0,
      });
    }
  }, [messages, oppositeMessenger, chatId]);

  useEffect(() => {
    if (width > 767) {
      document.body.classList.remove("overflow-hidden");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (isLoadingInvestor || isLoadingChat) {
    return (
      <div className=" w-[3.125rem] h-[3.125rem] rounded-full  bg-aurometalsaurus flex justify-center items-center ">
        <Loader className="flex justify-center items-center h-[1.875rem] w-[1.875rem] " />
      </div>
    );
  }

  if (isErrorInvestor || chatError) {
    return <NotFound customClass={"custom_design"} />;
  }

  if (!chat["homeowner"] || chat["homeowner"]?.id !== homehubData.id) {
    return <NotFound customClass={"custom_design"} />;
  }

  const chatUser = chat[oppositeMessenger] || {};

  const getChatStyles = () => {
    if (width <= 767) return { height: height - 135, overflowY: "scroll" };
  };



  return (
    <>
      <div className={clsx(styles.chatBox, "lg:max-w-[578px] w-full")}>
        <div className={clsx(styles.CardBody, "w-full")}>
          <div className={clsx(styles.chatList, "card")}>
            <div className={styles.header}>
              <div className="flex justify-between w-full gap-2 items-center">
                <div className="flex justify-start gap-2 items-center">
                  {chatUser?.avatar ? (
                    <img
                      src={chatUser?.avatar}
                      alt="profile-img"
                      className="w-10 h-10 object-cover rounded-[50%]"
                    />
                  ) : (
                    <div className="min-w-[2.5rem] h-10 object-cover rounded-[50%] bg-transparent border-yankeesblue border-[1px] flex justify-center text-yankeesblue text-[30px] items-center">
                      <p className="!text-lg !text-black">
                        {chatUser?.name
                          ?.split(" ")
                          .map((word) => word.charAt(0))
                          .join("")
                          .slice(0, 2)
                          .toUpperCase()}
                      </p>
                    </div>
                  )}
                  <div>
                    <h3 className="md:text-[16px]">{chatUser?.name}</h3>
                    <Online
                      isOnline={chatUser?.isOnline}
                      chatId={chatId}
                      role={"homeowner"}
                      userId={""}

                    />
                  </div>
                </div>
                <div>
                  <button
                    type="button"
                    className="absolute top-0 right-1 text-gray-400 mt-1 mb-4 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center transition-all outline-0 maxMd:top-1 maxMd:right-1 maxMd:m-0"
                    data-modal-toggle="staticModal"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    <img src={cross} alt="cross" width={16} />
                  </button>
                </div>
              </div>
            </div>

            <div
              className={clsx(
                styles.msgBody,
                "chat-msg-body scroolbar_msgbody"
              )}
              style={getChatStyles()}
            >
              <PublicChatConversation messages={messages} chatId={chatId}>
                {(uploadLoading || uploadError) && (
                  <ImageUploading progress={progress} error={uploadError} />
                )}
              </PublicChatConversation>
            </div>
            <PublicChatInput chatId={chatId} sendImage={sendImage} HomehubId={homehubData.id} />
          </div>
        </div>
      </div>

    </>
  );
};

export default PublicChatMessages;
