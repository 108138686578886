import React from "react";
import { getServerErrors } from "../../helpers/util";
import { svgLogo } from "../../api/data";
import clsx from "clsx";

const ErrorAlertMessage = ({ errors, className }) => {
  return (
    <div
      className={clsx(
        "flex text-sm text-red-700 bg-softBlush rounded-lg maxMd:mb-3 w-fit mx-auto",
        className
      )}
      role="alert"
    >
      <ul className="text-crimsonRed p-3 list-outside flex items-center justify-center gap-1">
        {svgLogo.errorIcon}
        {getServerErrors(errors).map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    </div>
  );
};

export default ErrorAlertMessage;
