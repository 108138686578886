import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie"; // Import js-cookie for cookie manipulation
import { getToken } from "./util"; // Assuming getToken checks cookies for token

const withoutAuthentication = (ComposedComponent) => {
  const Component = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      const tokenFromCookies = getToken("Token");

      // 1. Check if the token exists in cookies
      if (tokenFromCookies) {
        navigate("/"); // Redirect to home if token exists
        return;
      }

      // 2. Check if token exists in the URL
      const params = new URLSearchParams(location.search);
      const tokenFromParams = params.get("token");

      if (tokenFromParams) {
        // 3. Set token in cookies and clean URL
        Cookies.set("Token", tokenFromParams); // Set token in cookies
        const cleanURL = location.pathname;
        navigate(cleanURL, { replace: true }); // Remove token from URL and navigate
      }
    }, [location, navigate]);

    // 4. After token handling, check cookies again
    if (getToken("Token")) {
      return <Navigate to="/" />;
    }

    // 5. If no token, render the component
    return <ComposedComponent {...props} />;
  };

  return <Component />;
};

export default withoutAuthentication;