import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie"; // Import js-cookie for cookie manipulation
import { getToken } from "./util"; // Assuming getToken checks cookies for token

const withAuthentication = (ComposedComponent) => {
  const Component = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    // Parse the token from the URL params

    const params = new URLSearchParams(location.search);
    const tokenFromParams = params.get("token");

    // If the token exists in the URL params, set it in the cookies
    if (tokenFromParams) {
      Cookies.set("Token", tokenFromParams); // Set token in the cookie

      // Remove the token from the URL by navigating without the token parameter
      const cleanURL = location.pathname;
      navigate(cleanURL, { replace: true }); // Replace the URL without the token
    }

    // If the token exists in the cookies, render the wrapped component
    if (getToken("Token")) {
      return <ComposedComponent {...props} />;
    }

    // If no token is found, redirect to the login page
    return <Navigate to="/agents/login" />;
  };

  return <Component />;
};

export default withAuthentication;