import React, { createContext, useContext, useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useFormik } from "formik";
import { AGENT_TYPE_SINGLE_AGENT, AGENT_TYPE_TEAM } from "../helpers/constants";
import {
  agentSignupApi,
  agentSignupCheckDomainApi,
  agentSignupSentOtpApi,
  agentSignupSuggestDomainNameApi,
  agentSignupVerifyOtpApi,
} from "../api/agent";
import {
  agentSignupStepFiveSchema,
  agentSignupStepFourSchema,
  agentSignupStepThreeSchema,
  agentSignupStepTwoSingleAgentSchema,
} from "../../modules/agents/auth/signup/validation";

const OnboardingContext = createContext();

export const OnBoardingProvider = ({ children }) => {
  const [searchParams] = useSearchParams();
  const priceId = searchParams.get("price_id");
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1);
  const [progressLevel, setProgressLevel] = useState(1);
  const [domainAvailabilityMessage, setDomainAvailabilityMessage] =
    useState(null);
  // need to use this state instead of isError property of useMutation bcoz for the domainSuggestionFn the isError of domain check will not get set.
  const [isErrorDomainCheck, setIsErrorDomainCheck] = useState(false);

  // That api we're using for signup agent.
  const { mutate: agentSignupMutate, isLoading: isLoadingAgentSignup } =
    useMutation((data) => agentSignupApi(data), {
      onSuccess: (res) => {
        if (res.data.session_url) {
          window.location.replace(res.data.session_url);
          return;
        } else if (res.data.login_url) {
          window.location.replace(`${res.data.login_url}?onBoarding`);
          return;
        } else {
          navigate("/agents/login?onBoarding");
        }
        toast.success(res?.data?.message);
      },
      onError: (errors) => {
        toast.error(errors?.response?.data?.message);
      },
    });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      type: "",
      password: "",
      password_confirmation: "",
      team_name: "",
      team_phone: "",
      domain: "",
      otp: "",
      logo: "",
      brokerage: {
        name: "",
        phone: "",
        city: "",
        state: "",
        zip_code: "",
        address: "",
        disclaimer: "",
      },
    },
    validationSchema: () => {
      const agentSignupValidationSchemas = {
        2: agentSignupStepTwoSingleAgentSchema(
          formik.values.type === AGENT_TYPE_SINGLE_AGENT
        ),
        3: agentSignupStepThreeSchema,
        4: agentSignupStepFourSchema,
        5: agentSignupStepFiveSchema,
      };
      return agentSignupValidationSchemas[currentStep];
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      values.domain = `${values.domain}.${process.env.REACT_APP_HOST_NAME}`;
      if (priceId) {
        values.price_id = priceId;
      }
      let data = new FormData();

      // Append simple fields
      data.append("first_name", values.first_name);
      data.append("last_name", values.last_name);
      data.append("email", values.email);
      data.append("phone", values.phone);
      data.append("type", values.type);
      data.append("password", values.password);
      data.append("password_confirmation", values.password_confirmation);
      data.append("team_name", values.team_name);
      data.append("team_phone", values.team_phone);
      data.append("logo", values.logo);
      data.append("domain", `${values.domain}`);
      data.append("otp", values.otp);
      if (priceId) {
        data.append("price_id", priceId);
      }

      const brokerage = {
        name: values.brokerage.name,
        phone: values.brokerage.phone,
        city: values.brokerage.city,
        state: values.brokerage.state,
        zip_code: values.brokerage.zip_code,
        address: values.brokerage.address,
        disclaimer: values.brokerage.disclaimer,
      };

      // Append brokerage as a single JSON string
      data.append("brokerage", JSON.stringify(brokerage));

      // Call the mutation function with FormData
      agentSignupMutate(data);
    },
  });

  // That api we're using for suggest domain
  const { mutate: domainSuggestionFn } = useMutation(
    (data) => agentSignupSuggestDomainNameApi(data),
    {
      onSuccess: (response) => {
        setIsErrorDomainCheck(false);
        setDomainAvailabilityMessage(response?.data?.message);
        formik.setFieldValue("domain", response?.data?.domain);
      },
      onError: (error) => {
        toast.success(error?.message);
      },
    }
  );

  // That api we're using for check domain
  const { mutate: domainCheckFn } = useMutation(
    (data) => agentSignupCheckDomainApi(data),
    {
      onSuccess: (response) => {
        setIsErrorDomainCheck(false);
        setDomainAvailabilityMessage(response?.data?.message);
      },
      onError: (error) => {
        setIsErrorDomainCheck(true);
        setDomainAvailabilityMessage(error?.response?.data?.message);
      },
    }
  );

  // that api call we're using for send otp
  const { mutate: sendOtpFn, isLoading: isLoadingSendOtp } = useMutation(
    (data) => agentSignupSentOtpApi(data),
    {
      onSuccess: (res) => {
        if (currentStep === 2) {
          handleNextStep();
        }
        toast.success(res?.data?.message);
      },
      onError: (error) => {
        if (error.response.data.errors) {
          const errorMessages = error.response.data.errors;

          Object.keys(errorMessages).forEach((key) => {
            errorMessages[key].forEach((message) => {
              formik.setFieldError(key, message);
            });
          });
        }
      },
    }
  );

  // that api call we're using for verify otp
  const {
    mutate: verifyOtpFn,
    isError: isErrorVerifyOtp,
    isLoading: isLoadingVerifyOtp,
  } = useMutation((data) => agentSignupVerifyOtpApi(data), {
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      handleNextStep();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const handleNextStep = () => {
    const nextStep =
      currentStep === 3 && formik?.values?.type === AGENT_TYPE_SINGLE_AGENT
        ? 5
        : currentStep + 1;
    setCurrentStep(nextStep);
    setProgressLevel(nextStep);
  };

  // that we're using for back rendered componets.
  const onBackButtonClick = () => {
    if (currentStep === 5 && formik?.values?.type === AGENT_TYPE_SINGLE_AGENT) {
      setCurrentStep(currentStep - 3);
      setProgressLevel(currentStep - 3);
    } else if (currentStep === 4 && formik?.values?.type === AGENT_TYPE_TEAM) {
      setCurrentStep(currentStep - 2);
      setProgressLevel(currentStep - 2);
    } else {
      setCurrentStep(currentStep - 1);
      setProgressLevel(currentStep - 1);
    }
  };

  return (
    <OnboardingContext.Provider
      value={{
        formik,
        progressLevel,
        currentStep,
        domainAvailabilityMessage,
        isErrorVerifyOtp,
        isLoadingVerifyOtp,
        isLoadingSendOtp,
        isErrorDomainCheck,
        isLoadingAgentSignup,
        handleNextStep,
        domainSuggestionFn,
        domainCheckFn,
        sendOtpFn,
        verifyOtpFn,
        onBackButtonClick,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnBoarding = () => useContext(OnboardingContext);
