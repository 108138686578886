import React from 'react'
import LoginRightComponent from './LoginRightComponent'
import logo from "../../../assets/images/logotxt.svg";
import clsx from 'clsx';


const CommonLogin = ({ userType, isError, config, formik, passwordShown, setPasswordShown, isLoading, forgotPasswordUrl, navigate, error, userTypeConfig }) => {
    return (
        <div
            className={clsx(
                "w-1/2 flex flex-col justify-center items-center gap-10 maxLg:gap-6 maxLg:w-full z-0",
                userType === "agents"
                    ? "px-14 py-8 maxLg:p-5"
                    : "maxLg:p-5 agent__auth--bg",
                // { "lg:!justify-end": isError }
            )}
        >
            <div className="w-full text-white text-center lg:hidden">
                <img
                    src={logo}
                    alt="logo"
                    className="lg:h-10 h-8 lg:mb-8 maxLg:mb-4 maxLg:mx-auto"
                />
                <h2 className="text-2xl maxLg:text-xl font-semibold mb-4 maxLg:mb-2">
                    {config.heading}
                </h2>
                {userType !== "agents" && (
                    <p className="text-sm maxLg:max-w-[22rem] maxLg:mx-auto">
                        Log in to manage listings, track leads, and close deals with
                        ease, all in one place.
                    </p>
                )}
            </div>

            <LoginRightComponent
                formik={formik}
                passwordShown={passwordShown}
                setPasswordShown={setPasswordShown}
                isLoading={isLoading}
                forgotPasswordUrl={forgotPasswordUrl}
                navigate={navigate}
                userType={userType}
                isError={isError}
                error={error}
                userTypeConfig={userTypeConfig}
            />
        </div>
    )
}

export default CommonLogin