import api from "..";

export const signup = (data) => api.post("sellers/signup", data);

export const login = (data) => api.post("sellers/auth/login", data);

export const resetPassword = (data) =>
  api.post("sellers/auth/reset/password", data);

export const forgotPassword = (data) =>
  api.post("sellers/auth/password/email", data);

// export const logout = (data) => api.post("sellers/auth/logout", data);

export const getProperties = (
  page,
  search,
  orderByField,
  orderBy,
  params,
  agentId
) =>
  api
    .get("properties", {
      params: {
        page,
        search,
        order_by_field: orderByField,
        order_by: orderBy,
        agent_id: agentId,
        ...params,
      },
    })
    .then((response) => response.data);

export const getAgentProperties = (
  page,
  search,
  orderByField,
  orderBy,
  params,
  agentId
) =>
  api
    .get("agent/properties", {
      params: {
        page,
        search,
        order_by_field: orderByField,
        order_by: orderBy,
        agent_id: agentId,
        ...params,
      },
    })
    .then((response) => response.data);

export const addProperty = (data) => api.post("properties", data);

export const updateProperty = (property, data) =>
  api.post(`properties/${property}`, data);

export const deleteProperty = (property) =>
  api.delete(`properties/${property}`);

export const getBuyers = (page, search = "") => {
  return api.get("sellers/buyers", {
    params: {
      page,
      search,
    },
  });
};

export const addBuyer = (data) => api.post("sellers/buyers", data);

export const getBuyer = (buyer) => api.get(`sellers/buyers/${buyer}`);

export const updateBuyer = (buyer, data) =>
  api.put(`sellers/buyers/${buyer}`, data);

export const updateProfile = (data) => api.post("sellers/profile", data);

export const getOffers = (page, search, orderByField, orderBy) =>
  api.get("my-properties-offers", {
    params: {
      page,
      search,
      order_by_field: orderByField,
      order_by: orderBy,
    },
  });

export const agentsOffers = (
  page,
  offerType,
  searchKeyword,
  orderByField,
  orderBy
) =>
  api.get("agents/offers", {
    params: {
      page,
      search: searchKeyword,
      order_by_field: orderByField,
      order_by: orderBy,
      status: offerType,
    },
  });

export const markAsClose = (offer, data) =>
  api.post(`offer/${offer}/mark-as-close`, data);

//homeowners

export const getHomeOwners = (page, search = "") => {
  return api.get("home-owner-users", {
    params: {
      page,
      search,
    },
  });
};

export const getAllHomeOwners = () => {
  return api.get("home-owner-users", {
    params: {
      limit: "all",
    },
  });
};

export const getAllBuyers = () => {
  return api.get("offer-investors", {
    params: {
      limit: "all",
    },
  });
};

export const addHomeOwner = (data) => api.post("home-owner-users", data);

export const getHomeOwner = (homeOwner) =>
  api.get(`home-owner-users/${homeOwner}`);

export const updateHomeOwner = (homeOwner, data) =>
  api.put(`home-owner-users/${homeOwner}`, data);

export const deleteHomeOwner = (homeOwner) =>
  api.delete(`home-owner-users/${homeOwner}`);

export const acceptProperty = (data, hoRequestId) =>
  api.post(`accept-property/${hoRequestId}`, data);
