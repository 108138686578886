import React from "react";

import { requestModalSuccessMessage } from "../../../../shared/helpers/util";

import cross from "../../../../assets/images/closeSideMenu.svg";
import { ReactComponent as SentRequestIcon } from "../../../../assets/images/homehub-icon/send-request.svg";

const SentRequestModal = ({ onCloseSentRequestModal, requestType }) => {
  return (
    <div className="modal-card flex items-center flex-col text-center w-full p-[3rem] max-w-[30.25rem] h-[20.94rem] rounded-2xl">
      <div className="flex items-center flex-col text-center max-w-[20.5rem] pt-[0.2rem]">
        <button
          type="button"
          onClick={onCloseSentRequestModal}
          className="absolute top-0 right-3.5 text-gray-400 mt-4 mb-4 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center transition-all outline-0"
          data-modal-toggle="staticModal"
        >
          <img src={cross} alt="cross" width={16} />
        </button>
        <SentRequestIcon />
        <h2 className="font-bold text-[#3B4249C7] text-xl mb-[0.9rem] mt-[0.8rem] w-full text-left tracking-[0.36px] leading-[42px]">
          Your {requestType === "OFFER" && "offer"} request has been sent.
        </h2>
        <p className="text-base font-medium text-[#3B4249C7] text-left leading-[24.5px]">
          {requestModalSuccessMessage[requestType]}
        </p>
      </div>
    </div>
  );
};

export default SentRequestModal;
