import React, { useState } from "react";

import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import clsx from "clsx";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@tanstack/react-query";
import MaskedInput from "react-text-mask";
import { toast } from "react-toastify";

import styles from "../../../assets/css/signup.module.css";

import { getSignupUser, validateOnSignUp } from "../../../shared/api/investor";
import { signup as sellerSignUpApi } from "../../api/seller";
import { useInvestorSignUpData } from "../../../store/investorSignup";
import { phoneMask } from "../../api/data";
import { sellerSignupSchema } from "./validation";

import ServerErrors from "../../../shared/components/server-error";

import viewon from "../../../assets/images/eye.svg";
import viewoff from "../../../assets/images/eye-off.svg";
import logo from "../../../assets/images/Logo3.png";
import Loader from "../../components/loader/Loader";
import FormError from "../../components/form-control/FormError";

const SignUp = ({ loginUrl, setStep, setSignupUserData, signupUserData }) => {
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [params] = useSearchParams();

  const location = useLocation();

  const uniqueHash = params.get("unique_hash");

  const setInvestorSignUpData = useInvestorSignUpData(
    (state) => state.setInvestorSignUpData
  );

  const {
    mutate: mutateSellerSignUp,
    isLoading: sellerSignUpApiLoding,
    error: sellerError,
    isError: sellerIsError,
  } = useMutation((data) => sellerSignUpApi(data), {
    onSuccess: () => {
      formik.handleReset();
      toast.success("Verification link sent to your email!");
      navigate(loginUrl);
    },
  });

  const {
    mutate: mutateInvestorValidate,
    error: investorError,
    isError: investorIsError,
    isLoading: validateOnSignUpLoading,
  } = useMutation((data) => validateOnSignUp(data), {
    onSuccess: (res) => {
      setInvestorSignUpData(res?.data?.data);
      setStep(2);
    },
  });

  const formik = useFormik({
    initialValues: {
      first_name: signupUserData?.first_name || "",
      last_name: signupUserData?.last_name || "",
      email: signupUserData?.email || "",
      phone: signupUserData?.phone || "",
      password: "",
    },
    validationSchema: sellerSignupSchema,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (location.pathname.includes("sellers/")) {
        const request = uniqueHash
          ? { ...values, unique_hash: uniqueHash }
          : values;
        mutateSellerSignUp(request);
      }

      if (location.pathname.includes("buyers/")) {
        const request = uniqueHash
          ? { ...values, unique_hash: uniqueHash }
          : values;

        mutateInvestorValidate(request);
      }
    },
  });

  useQuery(["get-signup-user"], () => getSignupUser(uniqueHash), {
    enabled: !!uniqueHash,
    onSuccess: (res) => {
      setSignupUserData(res.data.data);
    },
  });

  return (
    <>
      <section
        className={clsx(styles.custom_container, "justify-start h-full")}
      >
        <Link to="/">
          <img src={logo} alt="logo-img" className="mt-4 mb-8 w-44" />
        </Link>
        <div className={styles.card}>
          <div className="grid md:grid-cols-2">
            <div className={styles.cardtext}>
              <h2 className="title_lg mb-2">Join Upside for free.</h2>
              <p className="maxMd:text-base text-lg text-black font-medium">
                if you already have an account you can{" "}
                <Link to={loginUrl} className="text-steelblue">
                  Sign in here
                </Link>
              </p>
            </div>
            <div>
              <form
                className={styles.inputRight}
                onSubmit={formik.handleSubmit}
              >
                <div className="maxMd:mb-3 mb-4">
                  <label className={styles.form_label}>First name</label>
                  <input
                    type="text"
                    name="first_name"
                    className={clsx(styles.form_control, {
                      "border border-red-500": formik.errors.first_name,
                    })}
                    placeholder="First Name"
                    onChange={formik.handleChange}
                    value={formik.values.first_name}
                  />
                  <FormError error={formik.errors.first_name} />
                </div>
                <div className="maxMd:mb-3 mb-4">
                  <label className={styles.form_label}>Last name</label>
                  <input
                    type="text"
                    className={clsx(styles.form_control, {
                      "border border-red-500": formik.errors.last_name,
                    })}
                    placeholder="Last Name"
                    onChange={formik.handleChange}
                    value={formik.values.last_name}
                    name="last_name"
                  />
                  <FormError error={formik.errors.last_name} />
                </div>
                <div className="maxMd:mb-3 mb-4">
                  <label className={styles.form_label}>Phone</label>
                  <MaskedInput
                    mask={phoneMask}
                    className={clsx(styles.form_control, {
                      "border border-red-500": formik.errors.phone,
                    })}
                    placeholder="Phone"
                    guide={false}
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                  />
                  <FormError error={formik.errors.phone} />
                </div>
                <div className="maxMd:mb-3 mb-4">
                  <label className={styles.form_label}>Email</label>
                  <input
                    type="text"
                    className={clsx(styles.form_control, "!pr-0", {
                      "border border-red-500": formik.errors.email,
                    })}
                    placeholder="Email"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  <FormError error={formik.errors.email} />
                </div>
                <div className={clsx(styles.view_icon, "maxMd:mb-3 mb-4")}>
                  <label className={styles.form_label}>Password</label>
                  <div className="relative">
                    <input
                      type={passwordShown ? "text" : "password"}
                      className={clsx(styles.form_control, {
                        "border border-red-500": formik.errors.password,
                      })}
                      placeholder="Password"
                      name="password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                    <FormError error={formik.errors.password} />
                    {passwordShown && (
                      <button
                        type="button"
                        onClick={() => setPasswordShown(false)}
                      >
                        <img src={viewoff} alt="eye-icon" />
                      </button>
                    )}
                    {!passwordShown && (
                      <button
                        type="button"
                        onClick={() => setPasswordShown(true)}
                      >
                        <img src={viewon} alt="view-icon" />
                      </button>
                    )}
                  </div>
                </div>
                {sellerIsError && <ServerErrors errors={sellerError} />}
                {investorIsError && <ServerErrors errors={investorError} />}
                <button
                  type="submit"
                  className={clsx(
                    "btn btn-primary flex items-center justify-center maxMd:mt-6 mt-1 w-full py-6",
                    {
                      disabled:
                        sellerSignUpApiLoding || validateOnSignUpLoading,
                    }
                  )}
                >
                  <span>
                    {location.pathname.includes("investors/")
                      ? "Continue"
                      : "Sign Up"}{" "}
                  </span>
                  {(sellerSignUpApiLoding || validateOnSignUpLoading) && (
                    <Loader />
                  )}
                </button>
                <div className="pt-11 maxMd:pt-5 maxMd:pb-14">
                  <p className=" text-[10.5px] ">
                    By proceeding, you explicitly agree to receive phone calls
                    and text messages at the provided number. These
                    communications may include marketing content delivered
                    through auto-dialing systems, pre-recorded messages,
                    artificial voices, and emails from Upside.This consent
                    remains valid even if you are registered on a corporate,
                    state, or national Do Not Call list. By continuing, you also
                    acknowledge and accept our Terms of Service and Privacy
                    Policy, which pertain to the information concerning you.
                    Standard message and data rates may apply. If you require
                    assistance, you can text "Help" for help and "Stop" to opt
                    out.
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUp;
