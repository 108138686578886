import React, { useState } from "react";

import clsx from "clsx";

import MaskedInput from "react-text-mask";

import Style from "../../../../../../assets/css/agent/auth.module.css";

import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { signup as sellerSignUpApi } from "../../../../../../shared/api/seller";

import {
  getSignupUser,
  validateOnSignUp,
} from "../../../../../../shared/api/investor";
import {
  formatPhoneNumber,
  phoneMask,
} from "../../../../../../shared/api/data";
import ServerErrors from "../../../../../../shared/components/server-error";
import FormLabel from "../../../../../../shared/components/agent-form-control/FormLabel";
import FormInput from "../../../../../../shared/components/agent-form-control/FormInput";
import FormError from "../../../../../../shared/components/form-control/FormError";
import FormButton from "../../../../../../shared/components/agent-form-control/FormButton";
import TogglePasswordVisibilityButton from "../../../../../../shared/components/password-visibility/TogglePasswordVisibilityButton";
import { buyerSignupSchema } from "../../../../../../shared/auth/signup/validation";
import { useInvestorSignUpData } from "../../../../../../store/investorSignup";
import { unmaskPhone } from "../../../../../../shared/helpers/util";

const OnboardingStepOneForm = ({
  setStep,
  setSignupUserData,
  signupUserData,
}) => {
  const navigate = useNavigate();

  const [params] = useSearchParams();

  const location = useLocation();

  const uniqueHash = params.get("unique_hash");

  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [termsError, setTermsError] = useState(false);

  const setInvestorSignUpData = useInvestorSignUpData(
    (state) => state.setInvestorSignUpData
  );

  const investorSignUpData = useInvestorSignUpData(
    (state) => state.investorSignUpData
  );

  const {
    mutate: mutateSellerSignUp,
    isLoading: sellerSignUpApiLoding,
    error: sellerError,
    isError: sellerIsError,
  } = useMutation((data) => sellerSignUpApi(data), {
    onSuccess: () => {
      formik.handleReset();
      toast.success("Verification link sent to your email!");
      navigate("/buyers/login");
    },
  });

  const {
    mutate: mutateInvestorValidate,
    error: investorError,
    isError: investorIsError,
    isLoading: validateOnSignUpLoading,
  } = useMutation((data) => validateOnSignUp(data), {
    onSuccess: (res) => {
      setInvestorSignUpData(res?.data?.data);
      setStep(2);
    },
  });

  const formik = useFormik({
    initialValues: {
      first_name:
        signupUserData?.first_name || investorSignUpData?.first_name || "",
      last_name:
        signupUserData?.last_name || investorSignUpData?.last_name || "",
      email: signupUserData?.email || investorSignUpData?.email || "",
      phone: signupUserData?.phone || investorSignUpData?.phone || "",
      password: "" || investorSignUpData?.password,
      password_confirmation: investorSignUpData?.password_confirmation || "",
    },
    validationSchema: buyerSignupSchema,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (!isTermsAccepted) {
        setTermsError(true);
        return;
      }

      const formattedValues = {
        ...values,
        phone: unmaskPhone(values.phone),
      };

      if (location.pathname.includes("sellers/")) {
        const request = uniqueHash
          ? { ...formattedValues, unique_hash: uniqueHash }
          : formattedValues;
        mutateSellerSignUp(request);
      }

      if (location.pathname.includes("buyers/")) {
        const request = uniqueHash
          ? { ...formattedValues, unique_hash: uniqueHash }
          : formattedValues;

        mutateInvestorValidate(request);
      }
    },
  });

  useQuery(["get-signup-user"], () => getSignupUser(uniqueHash), {
    enabled: !!uniqueHash,
    onSuccess: (res) => {
      setSignupUserData(res.data.data);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="pb-5 form_signup">
      <div className="maxMd:mb-3 mb-4">
        <FormLabel title="First Name" className={Style.formLabel} />
        <FormInput
          type="text"
          name="first_name"
          placeholder="Enter first name"
          className={clsx(Style.formInput, {
            "is-error": formik.errors?.first_name,
          })}
          value={formik.values.first_name}
          onChange={formik.handleChange}
        />
        <FormError error={formik.errors.first_name} />
      </div>
      <div className="maxMd:mb-3 mb-4">
        <FormLabel title="Last Name" className={Style.formLabel} />
        <FormInput
          type="text"
          name="last_name"
          placeholder="Enter last name"
          className={clsx(Style.formInput, {
            "is-error": formik.errors?.last_name,
          })}
          value={formik.values.last_name}
          onChange={formik.handleChange}
        />
        <FormError error={formik.errors.last_name} />
      </div>
      <div className="maxMd:mb-3 mb-4">
        <FormLabel title="Phone #" className={Style.formLabel} />
        <MaskedInput
          mask={phoneMask}
          guide={false}
          name="phone"
          placeholder="Enter phone #"
          className={clsx(Style.formInput, {
            "is-error": formik.errors?.phone,
          })}
          value={formatPhoneNumber(formik.values.phone)}
          onChange={formik.handleChange}
        />
        <FormError error={formik.errors.phone} />
      </div>
      <div className="maxMd:mb-3 mb-4">
        <FormLabel title="Email" className={Style.formLabel} />
        <FormInput
          type="text"
          name="email"
          placeholder="Enter email address"
          className={clsx(Style.formInput, {
            "is-error": formik.errors?.email,
          })}
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <FormError error={formik.errors.email} />
      </div>
      <div className="maxMd:mb-3 mb-4">
        <FormLabel title="Password" className={Style.formLabel} />
        <div className="relative mt-2">
          <FormInput
            type={passwordShown ? "text" : "password"}
            name="password"
            placeholder="Enter password"
            className={clsx(Style.formInput, "pr-11", "mt-0", {
              "is-error": formik.errors?.password,
            })}
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          <TogglePasswordVisibilityButton
            isPasswordShown={passwordShown}
            togglePasswordVisibility={() => setPasswordShown(!passwordShown)}
          />
        </div>
        <FormError error={formik.errors.password} />
      </div>
      <div className="mb-4">
        <FormLabel title="Confirm Password" className={Style.formLabel} />
        <div className="relative mt-1">
          <FormInput
            type={confirmPasswordShown ? "text" : "password"}
            name="password_confirmation"
            placeholder="Enter confirm password"
            className={clsx(Style.formInput, "mt-0 pr-11", {
              "is-error": formik.errors?.password_confirmation,
            })}
            value={formik.values.password_confirmation}
            onChange={formik.handleChange}
          />
          <TogglePasswordVisibilityButton
            isPasswordShown={confirmPasswordShown}
            togglePasswordVisibility={() =>
              setConfirmPasswordShown(!confirmPasswordShown)
            }
          />
        </div>
        <FormError error={formik.errors.password_confirmation} />
      </div>
      {sellerIsError && <ServerErrors errors={sellerError} />}
      {investorIsError && <ServerErrors errors={investorError} />}
      <div className="flex gap-1 items-center">
        <input
          type="checkbox"
          className="h-4 w-4 accent-moonStone"
          checked={isTermsAccepted}
          onChange={() => {
            setIsTermsAccepted(!isTermsAccepted);
            setTermsError(false);
          }}
        />
        <p className="text-xs text-coolGrey ml-1">
          I agree to the{" "}
          <Link
            to={`${process.env.REACT_APP_LANDING_UPSIDE_URL}/terms-of-use.html`}
            className="text-moonStone"
          >
            terms & conditions.
          </Link>
        </p>
        {termsError && <p className="text-xs text-red-500">(Required)</p>}
      </div>

      <FormButton
        type="submit"
        className={clsx(
          Style.formButton,
          "flex justify-center font-medium items-center"
        )}
        title={"Save & Continue"}
        loader={sellerSignUpApiLoding || validateOnSignUpLoading}
        disabled={sellerSignUpApiLoding || validateOnSignUpLoading}
      />
      <p className="mt-4 mb-8 maxMd:mt-2 text-center text-coolGrey text-base maxSm:text-xs font-medium">
        Already have an account?{" "}
        <Link to="/buyers/login" className="text-metallicBlue">
          Sign In
        </Link>
      </p>
    </form>
  );
};

export default OnboardingStepOneForm;
