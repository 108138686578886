import clsx from "clsx";
import FormButton from "../../components/agent-form-control/FormButton";
import FormError from "../../components/form-control/FormError";
import TogglePasswordVisibilityButton from "../../components/password-visibility/TogglePasswordVisibilityButton";
import ErrorAlertMessage from "../../components/server-error/ErrorAlertMessage";
import FormInput from "../../components/agent-form-control/FormInput";
import FormLabel from "../../components/agent-form-control/FormLabel";

const LoginRightComponent = ({
  formik,
  passwordShown,
  setPasswordShown,
  isLoading,
  forgotPasswordUrl,
  navigate,
  userType,
  isError,
  error,
  userTypeConfig,
}) => {
  const config = userTypeConfig[userType];

  return (
    <>
      <form
        className={clsx(
          "bg-white maxLg:max-w-[26.5625rem] w-full rounded-lg",
          userType === "agents" ? "maxLg:p-5" : "p-14 maxLg:p-5"
        )}
        onSubmit={formik.handleSubmit}
      >
        <h2 className="md:text-2xl text-xl text-metallicBlue font-bold block">
          {config?.loginTitle}
        </h2>
        <p className="text-coolGrey mt-2 text-sm maxMd:text-xs">
          {config?.subHeading}
        </p>

        <div className="mb-4 mt-6 maxLg:mt-3">
          <FormLabel
            title="Email"
            className="text-nickel font-medium mt-2 text-sm maxMd:text-xs"
          />
          <FormInput
            type="email"
            name="email"
            className={clsx(
              "border hover:border-metallicBlue focus:border-metallicBlue rounded-lg w-full outline-0 p-2 h-[2.625rem] mt-1 hover:shadow-agentsAuthInputs focus:shadow-agentsAuthInputs transition text-sm",
              { "is-error": formik.errors?.email }
            )}
            placeholder="Enter your email"
            onChange={formik.handleChange}
            value={formik.values.email}
            autoComplete="email"
          />
          <FormError error={formik.errors.email} />
        </div>

        <div className="mb-4">
          <FormLabel
            title="Password"
            className="text-nickel font-medium mt-2 text-sm maxMd:text-xs"
          />
          <div className="relative mt-1">
            <FormInput
              type={passwordShown ? "text" : "password"}
              name="password"
              placeholder="Enter password"
              className={clsx(
                "border hover:border-metallicBlue focus:border-metallicBlue rounded-lg w-full outline-0 p-2 h-[2.625rem] hover:shadow-agentsAuthInputs focus:shadow-agentsAuthInputs transition text-sm",
                { "is-error": formik.errors?.password }
              )}
              value={formik.values.password}
              onChange={formik.handleChange}
              autoComplete="current-password"
            />
            <TogglePasswordVisibilityButton
              isPasswordShown={passwordShown}
              togglePasswordVisibility={() => setPasswordShown(!passwordShown)}
            />
          </div>
          <FormError error={formik.errors.password} />
        </div>

        <p
          onClick={() => navigate(forgotPasswordUrl)}
          className="text-sm my-4 maxLg:my-2 text-steelblue hover:text-[#394E77] font-medium cursor-pointer ml-auto text-right"
        >
          Forgot your password?
        </p>

        <FormButton
          type="submit"
          className="!bg-metallicBlue text-white w-full p-3 rounded-md mt-7 maxLg:mt-4 hover:!bg-[#4D6083] transition flex justify-center disabled:pointer-events-none disabled:opacity-[0.6] font-semibold items-center"
          title="Log in"
          loader={isLoading}
          disabled={isLoading}
        />

        {userType !== "admin" && (
          <p
            onClick={() => navigate(`/${userType}/signup`)}
            className="text-sm mt-5 maxLg:mt-3 text-[#6B7280] font-medium cursor-pointer text-center"
          >
            Don't have an account?{" "}
            <span className="text-steelblue hover:text-[#394E77]">Sign up</span>
          </p>
        )}

        {isError && <ErrorAlertMessage className="mt-5" errors={error} />}
      </form>
    </>
  );
};

export default LoginRightComponent;
