import React from "react";

import { Link } from "react-router-dom";
import clsx from "clsx";
import { object, string } from "yup";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import logo from "../../../assets/images/logotxt.svg";

import Loader from "../../components/loader/Loader";
import FormError from "../../components/form-control/FormError";
import FormInput from "../../components/agent-form-control/FormInput";
import FormLabel from "../../components/agent-form-control/FormLabel";
import FormButton from "../../components/agent-form-control/FormButton";

const AgentForgotPasswordPageSchema = object({
  email: string().email().required(),
});

const AgentForgotPasswordPage = ({ onSubmit, loginUrl }) => {
  const { mutate, isLoading } = useMutation((contact) => onSubmit(contact), {
    onError: (error, variables, context) => {
      toast.error(error.response.data.message);
    },
    onSuccess: (res) => {
      toast.success(res.data.message);
    },
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: AgentForgotPasswordPageSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      mutate(values);
    },
  });

  if (isLoading) {
    return (
      <Loader className="h-screen justify-center items-center flex text-2xl loader-content" />
    );
  }

  return (
    <>
      <div className="h-screen flex items-center justify-center p-5">
        <div className="flex max-w-[68.625rem] maxLg:max-w-[calc(100vh-50px)] mx-auto max-h-[40.5rem] maxLg:max-h-full overflow-hidden bg-white relative z-0 w-full shadow-agentsAuthCards rounded-[1.25rem] h-full">
          <div className="h-full maxLg:absolute maxLg:left-0 maxLg:right-0 maxLg:top-0 w-1/2 maxLg:w-full auth_aside--bg">
            <div className="relative h-full flex justify-center items-center">
              <div className="max-w-[26.25rem] text-white maxLg:hidden mb-[90px]">
                <img
                  src={logo}
                  alt="logo"
                  className="lg:h-10 h-8 mx-auto mb-5"
                />
                <h2 className="text-2xl font-medium mb-4">
                  Turn your database into a databank.
                </h2>
              </div>
            </div>
          </div>
          <div className="w-1/2 flex flex-col justify-center items-center gap-10 maxLg:gap-6 maxLg:w-full z-0 maxLg:p-5">
            <div className="w-full text-white text-center lg:hidden">
              <img src={logo} alt="logo" className="lg:h-10 h-8 mx-auto mb-5" />
              <h2 className="text-2xl maxLg:text-xl font-semibold mb-4 maxLg:mb-2">
                Turn your database into a databank.
              </h2>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="p-14 maxLg:p-5 bg-white maxLg:max-w-[26.5625rem] w-full maxLg:rounded-lg"
            >
              <h2 className="md:text-2xl text-xl text-metallicBlue font-bold block">
                Forgot Password
              </h2>
              <p className="text-[#6B7280] mt-2 text-sm maxMd:text-xs">
                Please enter your email and we will send you a reset link.
              </p>
              <div className="mb-4 mt-6 maxLg:mt-3">
                <FormLabel
                  title="Email"
                  className="text-nickel font-medium mt-2 text-sm maxMd:text-xs"
                />
                <FormInput
                  type="text"
                  name="email"
                  className={clsx(
                    "border hover:border-metallicBlue focus:border-metallicBlue rounded-lg w-full outline-0 p-2 h-[2.625rem] mt-1 hover:shadow-agentsAuthInputs focus:shadow-agentsAuthInputs transition text-sm",
                    {
                      "is-error": formik.errors?.email,
                    }
                  )}
                  placeholder="Enter your email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                <FormError error={formik.errors.email} />
              </div>

              <FormButton
                type="submit"
                className="!bg-metallicBlue text-white w-full p-3 rounded-md mt-7 maxLg:mt-4 hover:!bg-[#4D6083] transition flex justify-center disabled:pointer-events-none disabled:opacity-[0.6] font-semibold items-center"
                title="Send Reset Link"
                loader={isLoading}
                disabled={isLoading}
              />
              <p className="text-sm mt-5 maxLg:mt-3 text-[#6B7280] font-medium cursor-pointer text-center">
                Or{" "}
                <Link
                  to={loginUrl}
                  className="text-steelblue hover:text-[#394E77]"
                >
                  Sign in here
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentForgotPasswordPage;
