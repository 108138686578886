import clsx from "clsx";

import styles from "../../../assets/css/chat-messages.module.css";

import Loader from "../loader/Loader";

const ImageUploading = ({ progress, error }) => {
  return (
    <div className={clsx(styles.imageUploadPlaceholder, "ml-auto")}>
      {!error && (
        <div>
          <Loader />
          <div>Uploading:{Math.ceil(progress)}%</div>
        </div>
      )}
      {error && <span className="text-red-600">Error: {error}</span>}
    </div>
  );
};

export default ImageUploading;
