import React, { useState } from "react";

import clsx from "clsx";
import { Link } from "react-router-dom";
import { createPortal } from "react-dom";

import style from "../../../assets/css/homeowner/home.module.css";

import { onClickCall } from "../../../shared/helpers/util";
import { ChatMessageProvider } from "../../provider/ChatMessageProvider";
import PublicChatMessages from "../chat-messages/PublicChatMessages";

import mary from "../../../assets/images/default_agent.svg";

const PublicContactCard = ({ propertyData, isPublicRoute, homehubData }) => {
  const mountElement = document.getElementById("portal");

  const [isMortgageRateModalOpen, setIsMortgageRateModalOpen] = useState(false);

  const onCloseMessageChat = () => {
    setIsMortgageRateModalOpen(false);
  };

  return (
    <div className={clsx("p-6 flex flex-col justify-between", style.card)}>
      <div>
        {!propertyData?.agent?.avatar ? (
          <img
            src={mary}
            alt="avatar"
            className="w-[100px] h-[100px] rounded-full mb-2 mx-auto object-cover"
          />
        ) : (
          <img
            src={propertyData?.agent?.avatar}
            alt="avatar"
            className=" w-[100px] h-[100px] border-[2px]  rounded-full border-black mb-2 mx-auto object-cover"
          />
        )}

        <div className="text-center">
          <h3 className={style.headingMd}>
            {propertyData?.agent
              ? propertyData?.agent?.first_name
              : propertyData?.admin?.first_name}{" "}
            {propertyData?.agent
              ? propertyData?.agent?.last_name
              : propertyData?.admin?.last_name}
          </h3>
          <p className="text-black text-sm">REALTOR®</p>
          <p className="text-black text-sm">
            {propertyData?.agent
              ? propertyData?.agent?.email
              : propertyData?.admin?.email}
          </p>
        </div>
      </div>

      <div className="flex mt-8">
        {isPublicRoute === true ? (
          <button
            className="light-button hover:!bg-white hover:!text-jacarta w-full text-center "
            onClick={() => setIsMortgageRateModalOpen(true)}
          >
            Message
          </button>
        ) : (
          <Link
            to={`/${propertyData?.agent ? "agents" : "admins"}/messages/${
              propertyData?.agent
                ? propertyData?.agent.id
                : propertyData?.admin.id
            }`}
            className="light-button w-full hover:!bg-yankeesblue text-center"
            type="button"
          >
            Message
          </Link>
        )}

        <button
          className="light-button w-full hover:!bg-yankeesblue !mr-0"
          type="button"
          onClick={() =>
            onClickCall(
              propertyData?.agent?.phone ?? propertyData?.admin?.phone
            )
          }
        >
          Call
        </button>
      </div>

      {isMortgageRateModalOpen &&
        createPortal(
          <ChatMessageProvider>
            <PublicChatMessages
              homehubData={homehubData}
              setIsMortgageRateModalOpen={setIsMortgageRateModalOpen}
              propertyData={propertyData}
              onClose={() => onCloseMessageChat()}
              crtEntity={homehubData?.source === "HOMEHUB" ? "homeowner" : ""}
              optEntity={"agents"}
            />
          </ChatMessageProvider>,
          mountElement
        )}
    </div>
  );
};

export default PublicContactCard;
