import React from 'react'
import { forgotPassword } from '../../../../shared/api/admin'
import AgentForgotPasswordPage from '../../../../shared/auth/forgot-password/AgentForgotPasswordPage'

const AdminForgotPassword = () => {
  return (
    <AgentForgotPasswordPage
      onSubmit={forgotPassword}
      loginUrl='/admin/login'
    />
  )
}

export default AdminForgotPassword