import React from "react";

const ButtonLoader = ({ className = null, style = {} }) => {
  return (
    <div className={className} style={style}>
      <span className="button_loader flex items-center"></span>
    </div>
  );
};

export default ButtonLoader;
