import React, { useRef, useState } from "react";

import clsx from "clsx";
import MaskedInput from "react-text-mask";

import Style from "../../../../../../assets/css/agent/auth.module.css";

import { AGENT_TYPE_SINGLE_AGENT } from "../../../../../../shared/helpers/constants";
import { useOnBoarding } from "../../../../../../shared/provider/OnBoardingProvider";

import { phoneMask } from "../../../../../../shared/api/data";
import OnboardingTitle from "../../../../../../shared/components/agent-auth-title";
import FormInput from "../../../../../../shared/components/agent-form-control/FormInput";
import FormLabel from "../../../../../../shared/components/agent-form-control/FormLabel";
import FormButton from "../../../../../../shared/components/agent-form-control/FormButton";
import FormSubdomainInput from "../../../../../../shared/components/agent-form-control/FormSubdomainInput";
import FormError from "../../../../../../shared/components/form-control/FormError";
import TogglePasswordVisibilityButton from "../../../../../../shared/components/password-visibility/TogglePasswordVisibilityButton";
import { debounce } from "lodash";

const AgentOnboardingStepTwo = () => {
  const {
    formik,
    domainAvailabilityMessage,
    domainSuggestionFn,
    domainCheckFn,
    isLoadingSendOtp,
    isErrorDomainCheck,
    sendOtpFn,
  } = useOnBoarding();

  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const executeSearch = useRef(
    debounce(async (fn, value) => {
      await fn(value);
    }, 3000)
  ).current;

  const onFirstNameChange = async (e) => {
    formik.handleChange(e);

    if (
      formik?.values?.type !== AGENT_TYPE_SINGLE_AGENT ||
      e.target.value.length === 0
    ) {
      return;
    }

    await executeSearch(domainSuggestionFn, { name: e.target.value });
  };

  const onDomainChange = async (e) => {
    const value = e.target.value.trim().toLowerCase().replace(/\s+/g, "");

    formik.setFieldValue("domain", value);

    if (value.length === 0) return;

    await executeSearch(domainCheckFn, {
      domain: `${value}.${process.env.REACT_APP_HOST_NAME}`,
    });
  };

  const handleNext = (event) => {
    event.preventDefault();

    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) {
        return;
      }

      if (isErrorDomainCheck) return;
      sendOtpFn({
        email: formik.values.email,
        phone: formik.values.phone,
        name: formik.values.first_name,
      });
    });
  };

  return (
    <div className="max-w-[26.625rem] mx-auto mt-20">
      <OnboardingTitle
        title="Enter your personal details"
        subTitle="Let’s personalize your account. Please enter your information below."
      />
      <form className={Style.formLayout} onSubmit={handleNext}>
        <div className="mb-4">
          <FormLabel title="First Name" className={Style.formLabel} />
          <FormInput
            type="text"
            name="first_name"
            placeholder="Enter first name"
            className={clsx(Style.formInput, {
              "is-error": formik.errors?.first_name,
            })}
            value={formik.values.first_name}
            onChange={onFirstNameChange}
          />
          <FormError error={formik.errors.first_name} />
        </div>
        <div className="mb-4">
          <FormLabel title="Last Name" className={Style.formLabel} />
          <FormInput
            type="text"
            name="last_name"
            placeholder="Enter last name"
            className={clsx(Style.formInput, {
              "is-error": formik.errors?.last_name,
            })}
            value={formik.values.last_name}
            onChange={formik.handleChange}
          />
          <FormError error={formik.errors.last_name} />
        </div>
        <div className="mb-4">
          <FormLabel title="Phone #" className={Style.formLabel} />
          <MaskedInput
            mask={phoneMask}
            guide={false}
            name="phone"
            placeholder="Enter phone #"
            className={clsx(Style.formInput, {
              "is-error": formik.errors?.phone,
            })}
            value={formik.values.phone}
            onChange={formik.handleChange}
          />
          <FormError error={formik.errors.phone} />
        </div>
        <div className="mb-4">
          <FormLabel title="Email" className={Style.formLabel} />
          <FormInput
            type="text"
            name="email"
            placeholder="Enter email address"
            className={clsx(Style.formInput, {
              "is-error": formik.errors?.email,
            })}
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          <FormError error={formik.errors.email} />
        </div>
        {formik?.values?.type === AGENT_TYPE_SINGLE_AGENT && (
          <div className="mb-4">
            <div className="flex items-center">
              <FormLabel title="Subdomain" className={Style.formLabel} />
              {formik.values.domain?.length > 0 &&
                !formik.errors.domain &&
                domainAvailabilityMessage && (
                  <p
                    className={clsx("text-xs pl-1 text-green-600 mt-[0.7rem]", {
                      "!text-red-600": isErrorDomainCheck,
                    })}
                  >
                    ({domainAvailabilityMessage})
                  </p>
                )}
            </div>
            <FormSubdomainInput
              type="text"
              name="domain"
              inputWrapper={clsx({
                "is-error": formik.errors?.domain || isErrorDomainCheck,
              })}
              className="block w-full outline-0 p-4 rounded-lg"
              placeholder="Enter subdomain"
              value={formik.values.domain.trim().toLowerCase()}
              onChange={onDomainChange}
            />
            <FormError error={formik.errors.domain} />
          </div>
        )}
        <div className="mb-4">
          <FormLabel title="Password" className={Style.formLabel} />
          <div className="relative mt-2">
            <FormInput
              type={passwordShown ? "text" : "password"}
              name="password"
              placeholder="Enter password"
              className={clsx(Style.formInput, "pr-11", "mt-0", {
                "is-error": formik.errors?.password,
              })}
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            <TogglePasswordVisibilityButton
              isPasswordShown={passwordShown}
              togglePasswordVisibility={() => setPasswordShown(!passwordShown)}
            />
          </div>
          <FormError error={formik.errors.password} />
        </div>
        <div className="mb-4">
          <FormLabel title="Confirm Password" className={Style.formLabel} />
          <div className="relative mt-1">
            <FormInput
              type={confirmPasswordShown ? "text" : "password"}
              name="password_confirmation"
              placeholder="Enter confirm password"
              className={clsx(Style.formInput, "mt-0 pr-11", {
                "is-error": formik.errors?.password_confirmation,
              })}
              value={formik.values.password_confirmation}
              onChange={formik.handleChange}
            />
            <TogglePasswordVisibilityButton
              isPasswordShown={confirmPasswordShown}
              togglePasswordVisibility={() =>
                setConfirmPasswordShown(!confirmPasswordShown)
              }
            />
          </div>
          <FormError error={formik.errors.password_confirmation} />
        </div>
        <FormButton
          type="submit"
          className={clsx(Style.formButton, "flex justify-center items-center")}
          title="Save & Continue"
          loader={isLoadingSendOtp}
          disabled={isLoadingSendOtp}
        />
      </form>
    </div>
  );
};

export default AgentOnboardingStepTwo;
