/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

import clsx from "clsx";
import { Link } from "react-router-dom";

import defaulImages from "../../../assets/images/property-image-horizontal.png";

import OverlayStaticChart from "./OverlayStaticChart";

const OverlayStaticDetails = () => {
  return (
    <section className="main-content relative pt-24">
      <div className="flex items-center flex-wrap mb-3">
        <h1 className="md:text-2xl text-lg text-darkGreyBlue font-inter-extra-bold">
          My Home’s Dashboard{" "}
        </h1>
        <span className="ml-auto text-yankeesblue text-sm">
          Linked Properties
        </span>
      </div>
      <div className="grid xl:grid-cols-4 lg:grid-cols-3 gap-5">
        <div className="border border-silversand bg-white rounded-md p-4">
          <img src={defaulImages} alt="image" border="0" />
          <div className="mt-3">
            <h3>69-37 Nansen Street</h3>
            <h5>Flushing NY 11375</h5>
            <p>3 Bed | 2 Bath | 55 SqFt | 4 Built</p>
          </div>
        </div>
        <div className={"lg:col-span-2 py-3 px-5 card"}>
          <div className="flex gap-[0.625rem] mb-3">
            <h3 className={"text-black text-base font-semibold"}>
              Estimated home value
            </h3>
            <select className="ml-auto rounded-md outline-none border bg-transparent text-moonStone text-xs border-moonStone">
              <option>1 Year</option>
              <option>3 Year</option>
              <option>5 Year</option>
            </select>
          </div>
          <div className="flex gap-[0.625rem] items-center">
            <h1 className="text-yankeesblue font-inter-extra-bold lg:text-[2.5rem] text-2xl">
              $25
            </h1>
            <span className="text-xs text-black md:max-w-[15.625rem]">
              Your home has gained an estimated $256 since you purchased it.
            </span>
          </div>
          <OverlayStaticChart />
        </div>
        <div>
          <div
            className={"py-3 px-5 border border-silversand bg-white rounded-md"}
          >
            <h3 className={"text-black text-base font-semibold"}>
              Estimated equity in my home
            </h3>
            <h2 className="text-moonStone font-inter-extra-bold lg:text-[2.1875rem] text-2xl mt-3">
              $325,000
            </h2>
          </div>
          <div className="mt-5">
            <button className="dark-button_homehub w-full !bg-yankeesBlue">
              Get an offer on my home
            </button>
            <p className="text-black text-sm mt-5">
              Looking for the quickest way to sell your home? Get a cash offer
              and sell your home with certainty.
            </p>
          </div>
        </div>
      </div>
      <div className="grid xl:grid-cols-4 lg:grid-cols-3 gap-5 mt-5">
        <div
          className={clsx(
            "p-6",
            "border border-silversand bg-white rounded-md"
          )}
        >
          <h3 className={"text-black text-base font-semibold"}>
            My mortgage rate
          </h3>
          <p className={"my-2text-black text-[0.8125rem]"}>
            It’s important to know your mortgage rate and if decreasing rates
            make sense for you to refinance.
          </p>
          <div
            className={clsx(
              "flex p-3 max-w-[14.5625rem] gap-3 items-center bg-limeGreen border border-silversand bg-white rounded-md justify-between"
            )}
          >
            <p className="text-[0.8125rem] text-black font-inter-medium w-20">
              My estimated rate is
            </p>
            <div>
              <h3 className="text-[2.5rem] leading-10 inter-bold">34%</h3>
              <span className="text-aurometalsaurus text-xs font-semibold">
                30-Year Fixed
              </span>
            </div>
          </div>
          <Link className="mt-5 block text-moonStone font-inter-medium" to="#">
            See today’s rates
          </Link>
        </div>
        <div className="lg:col-span-2 grid md:grid-cols-2 gap-5">
          <div
            className={clsx("p-6 border border-silversand bg-white rounded-md")}
          >
            <h3 className={"text-black text-base font-semibold"}>
              Lower my monthly payment
            </h3>
            <p className={"my-2 text-black text-[0.8125rem]"}>
              Notify me to refinance when rates go down to my target rate.
            </p>
            <div className="flex gap-4 mt-[1.125rem] mb-[1.875rem]">
              <div
                className={clsx(
                  "max-w-[4.75rem] border border-silversand rounded-[0.1875rem] flex items-center"
                )}
              >
                <input className="w-full px-2 py-1" value={"25"} />
                <span className="bg-anflshwht p-2">%</span>
              </div>
              <div
                className={clsx(
                  "max-w-[6rem] border border-silversand rounded-[0.1875rem] flex items-center"
                )}
              >
                <input className="w-full px-2 py-1" value={"15"} />
                <span className="bg-anflshwht p-2">Years</span>
              </div>
            </div>
            <div className="flex gap-3 items-start">
              <label className="themeSwitcherTwo relative mt-2 inline-flex cursor-pointer select-none items-center">
                <input type="checkbox" className="sr-only" />

                <span
                  className={`slider flex h-7 w-[3.4375rem] items-center rounded-full p-1 duration-200 bg-silver`}
                >
                  <span
                    className={`dot h-4 w-4 rounded-full bg-white duration-200`}
                  ></span>
                </span>
              </label>
              <p className={clsx("my-2", "text-black text-[0.8125rem]")}>
                Turn on alerts to notify me when rates drop to my target rate so
                I can lower my monthly payment
              </p>
            </div>
          </div>
          <div
            className={clsx(
              "p-6",
              "border border-silversand bg-white rounded-md"
            )}
          >
            <h3 className={"text-black text-base font-semibold"}>
              Cash out my equity
            </h3>
            <p className={clsx("my-2", "text-black text-[0.8125rem]")}>
              You currenty have an estimated $325,000 in home equity.
            </p>
            <p className={clsx("mt-3", "text-black text-[0.8125rem]")}>
              Use cash from your home equity to make improvements, buy an
              investment property or to pay for any other necessary items.
            </p>
            <button className="dark-button_homehub w-full mt-10 text-sm">
              Get cash from my equity
            </button>
          </div>
        </div>
        <div
          className={clsx(
            "p-6",
            "border border-silversand bg-white rounded-md"
          )}
        >
          <a href="https://imgbb.com/">
            <img
              src="https://i.ibb.co/F0vtV8R/image.png"
              alt="image"
              border="0"
              className="mx-auto"
            />
          </a>
          <br />{" "}
          <div className="text-center">
            <h3 className={"text-black text-base font-semibold"}>
              Mary Reusch
            </h3>
            <p className="text-black text-sm">REALTOR®</p>
          </div>
          <div className="flex mt-8">
            <button className="light-button w-full hover:!bg-deepTeal">
              Message
            </button>
            <button className="light-button w-full hover:!bg-deepTeal">
              Call
            </button>
          </div>
        </div>
      </div>
      <div
        className={clsx(
          "border border-silversand bg-white rounded-md",
          "p-4 flex gap-5 mt-4"
        )}
      >
        <div>
          <img
            src="https://i.ibb.co/4MKzHtZ/image.png"
            alt="image"
            border="0"
            className="w-full"
          />
        </div>
        <div className="w-full">
          <div className="flex flex-wrap">
            <h3 className={"text-black text-base font-semibold"}>
              My neighborhood statistics this month
            </h3>
            <select
              className={clsx(
                "appearance-none !pr-[1.875rem] text-davysgrey text-sm border border-silversand outline-none p-2 rounded-md",
                "ml-auto"
              )}
            >
              <option>Last 30 Days</option>
              <option>Last 3 Year</option>
              <option>Last 5 Year</option>
            </select>
          </div>
          <div className="grid xl:grid-cols-4 lg:grid-cols-3 gap-5">
            <div className="mt-4">
              <p className="text-mutedGrey text-base">Avg. List Price</p>
              <h2 className="text-davygrey lg:text-[1.75rem] text-xl mt-1 font-inter-extra-bold">
                $675, 000
                <span className="text-moonStone text-base ml-2 font-inter-regular">
                  +5%
                </span>
              </h2>
            </div>
            <div className="mt-4">
              <p className="text-mutedGrey text-base">Avg. Sales Price</p>
              <h2 className="text-davygrey lg:text-[1.75rem] text-xl mt-1 font-inter-extra-bold">
                $681,000
                <span className="text-moonStone text-base ml-2 font-inter-regular">
                  +5%
                </span>
              </h2>
            </div>
            <div className="mt-4">
              <p className="text-mutedGrey text-base">Avg. Price/SqFt</p>
              <h2 className="text-davygrey lg:text-[1.75rem] text-xl mt-1 font-inter-extra-bold">
                203
                <span className="text-moonStone text-base ml-2 font-inter-regular">
                  +5%
                </span>
              </h2>
            </div>
            <div className="mt-4">
              <p className="text-mutedGrey text-base">Average Days on Market</p>
              <h2 className="text-davygrey lg:text-[1.75rem] text-xl mt-1 font-inter-extra-bold">
                $32
                <span className="text-moonStone text-base ml-2 font-inter-regular">
                  +5%
                </span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OverlayStaticDetails;
