import React from "react";

import ScrollToBottom from "react-scroll-to-bottom";
import moment from "moment";

import PublicMessage from "./PublicMessage";
import { HOMEOWNER } from "../../helpers/constants";

const PublicChatConversation = ({ messages, children, chatId }) => {
  const messagesByDate = {};
  messages.forEach((message) => {
    const momentDate = message.timestamp
      ? moment(message.timestamp.toDate())
      : moment();
    let date = momentDate.format("MM/DD/YYYY");
    const isToday = moment().format("MM/DD/YYYY") === date;
    date = isToday ? "Today" : date;

    if (!messagesByDate[date]) {
      messagesByDate[date] = [];
    }
    message.time = momentDate.format("HH:mm");
    messagesByDate[date].push(message);
  });

  return (
    <>
      <ScrollToBottom
        followButtonClassName="scroll_icon"
        initialScrollBehavior="auto"
        className="chatHeight"
      >
        <ul>
          {Object.keys(messagesByDate).map((date) => (
            <div className="date-container" key={date}>
              <p className="mb-5 text-center">{date}</p>
              {messagesByDate[date].map((message, index) => (
                <PublicMessage
                  key={message.id}
                  message={message.message}
                  isOwnMessage={message.senderType === HOMEOWNER}
                  time={message.time}
                  type={message.type}
                  messageId={message.id}
                  isDelete={message?.isDelete}
                  messageData={message}
                  chatId={chatId}
                  lastIndex={
                    index === messagesByDate[date].length - 1
                      ? message.id
                      : null
                  }
                />
              ))}
            </div>
          ))}
          {children}
        </ul>
      </ScrollToBottom>
    </>
  );
};

export default PublicChatConversation;
